import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Switch from '@components/common/switch';
import { useForm } from '@hooks/helpers/useForm';
import FormField from '@components/form_field';
import { FormProvider } from 'react-hook-form';
import Tabs from '@components/tabs';
import Checkbox from '@components/common/checkbox';
import { useUserContext } from '@hooks/context/useUserContext';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiUserService } from '@api';
import { TNotificationSettings } from '@xeppt/xeppt-sdk/types/user';

const tabs = ['card', 'personal', 'payments', 'schedule', 'marketing'];

const SettingsNotificationsLayout = () => {
    const { t } = useTranslation('settings', {
        keyPrefix: 'notifications'
    });
    const { user, refetchUser } = useUserContext();
    const { submitLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const formMethods = useForm({});
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const activeTabNotifications = useMemo(() => {
        return Object.keys(user?.notifications || {}).filter((item) => item?.startsWith(activeTab));
    }, [user, activeTab]);

    const isEmailActive = useMemo(() => {
        const values = formMethods.getValues();
        return !!activeTabNotifications.find((item) => values?.[item]?.['email']);
    }, [user, activeTabNotifications, formMethods.watch()]);

    const isSmsActive = useMemo(() => {
        const values = formMethods.getValues();
        return !!activeTabNotifications.find((item) => values?.[item]?.['sms']);
    }, [user, activeTabNotifications, formMethods.watch()]);

    const isPushActive = useMemo(() => {
        const values = formMethods.getValues();
        return !!activeTabNotifications.find((item) => values?.[item]?.['push']);
    }, [user, activeTabNotifications, formMethods.watch()]);

    useEffect(() => {
        if (user) {
            formMethods.reset(user?.notifications);
        }
    }, [user]);

    const { handleRequest: handleUpdateNotifications, isLoading: isUpdateNotificationsLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                return apiUserService.updateNotificationSettings(values);
            },
            onSuccess: () => {
                refetchUser();
                requestSuccessLocale('update_notifications_settings');
            },
            onError: requestErrorLocale
        });

    const bulkNotificationsUpdate = (type: 'email' | 'sms' | 'push') => {
        const activeStatuses = {
            sms: isSmsActive,
            email: isEmailActive,
            push: isPushActive
        };
        const values = formMethods.getValues();
        const activeTabValues: TNotificationSettings = {};
        const isAllActive = activeStatuses[type];
        activeTabNotifications.forEach((key) => {
            activeTabValues[key] = {
                ...values?.[key],
                [type]: !isAllActive
            };
        });

        formMethods.reset({ ...values, ...activeTabValues });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.title_wrapper}>
                <Typography variant="h4" className={styles.title}>
                    {t('title')}
                </Typography>
                <Typography variant="body3">{t('description')}</Typography>
            </div>
            <Tabs
                onChange={(val) => setActiveTab(val)}
                tabs={tabs.map((item) => ({ label: t(`tabs.${item}`), value: item }))}
                activeTab={activeTab}
            />
            <FormProvider {...formMethods}>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.header}>
                            <Typography variant="body1" weight="semibold">
                                {t('messages')}
                            </Typography>
                            <div className={styles.switches_wrapper}>
                                <div className={styles.switch_wrapper}>
                                    <Typography variant="body3">{t('email')}</Typography>{' '}
                                    <Switch
                                        checked={isEmailActive}
                                        onClick={() => bulkNotificationsUpdate('email')}
                                    />
                                </div>
                                <div className={styles.switch_wrapper}>
                                    <Typography variant="body3">{t('text')}</Typography>{' '}
                                    <Switch
                                        checked={isSmsActive}
                                        onClick={() => bulkNotificationsUpdate('sms')}
                                    />
                                </div>
                                {/*<div className={styles.switch_wrapper}>*/}
                                {/*    <Typography variant="body3">{t('push')}</Typography>{' '}*/}
                                {/*    <Switch*/}
                                {/*        checked={isPushActive}*/}
                                {/*        onClick={() => bulkNotificationsUpdate('push')}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {activeTabNotifications.map((item) => {
                            return (
                                <div className={styles.row} key={item}>
                                    <Typography variant="body3">
                                        {t(`notification_strings.${item}`)}
                                    </Typography>
                                    <div className={styles.switches_wrapper}>
                                        <FormField<boolean>
                                            name={`${item}.email`}
                                            renderComponent={({ value, onChange }) => {
                                                return (
                                                    <div className={styles.switch_wrapper}>
                                                        <Checkbox
                                                            checked={value}
                                                            onClick={() => onChange(!value)}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        />
                                        <FormField<boolean>
                                            name={`${item}.sms`}
                                            renderComponent={({ value, onChange }) => (
                                                <div className={styles.switch_wrapper}>
                                                    <Checkbox
                                                        checked={value}
                                                        onClick={() => onChange(!value)}
                                                    />
                                                </div>
                                            )}
                                        />
                                        {/*<FormField<boolean>*/}
                                        {/*    name={`${item}.push`}*/}
                                        {/*    renderComponent={({ value, onChange }) => (*/}
                                        {/*        <div className={styles.switch_wrapper}>*/}
                                        {/*            <Checkbox*/}
                                        {/*                checked={value}*/}
                                        {/*                onClick={() => onChange(!value)}*/}
                                        {/*            />*/}
                                        {/*        </div>*/}
                                        {/*    )}*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </FormProvider>
            <Button
                isLoading={isUpdateNotificationsLoading}
                onClick={handleUpdateNotifications}
                className={styles.save_button}
                variant="primary"
                size="normal">
                {submitLocale('save')}
            </Button>
        </motion.div>
    );
};

export default SettingsNotificationsLayout;
