import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';
import { apiContactService } from '@api';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import { useLocales } from '@hooks/helpers/useLocales';
import { TContact } from '@xeppt/xeppt-sdk/types';

export const useSendAndRequestModals = () => {
    const { onClose, onOpen, handleChangeLoading, changeModalData } = useModalContext();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const navigate = useNavigate();

    const handleOpenRequestAutodepositModal = (changeIsUpdate: (val: boolean) => void) => {
        onOpen({
            modalId: modalIds.SUCCESS_AUTODEPOSIT,
            onSubmit: () => {
                onClose();
                changeIsUpdate(false);
            }
        });
    };

    const handleDeleteContactModal = (id: string, refetchContacts: () => void) => {
        onOpen({
            modalId: modalIds.DELETE_CONTACT,
            onSubmit: () => {
                handleChangeLoading(true);
                apiContactService
                    .deleteContact(id)
                    .then(() => {
                        requestSuccessLocale('delete_contact');
                        refetchContacts();
                        navigate(routes.send_and_request_contacts);
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleDeleteWalletContact = (id: string, refetchContacts: () => void) => {
        onOpen({
            modalId: modalIds.DELETE_WALLET_CONTACT_MODAL,
            onSubmit: () => {
                handleChangeLoading(true);
                apiContactService
                    .deleteContact(id)
                    .then(() => {
                        requestSuccessLocale('delete_contact');
                        refetchContacts();
                        navigate(routes.send_and_request_contacts);
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleOpenContactPaymentModal = (contact: TContact) => {
        changeModalData(contact);
        onOpen({
            modalId: modalIds.CONTACT_PAYMENT_MODAL
        });
    };

    return {
        handleOpenRequestAutodepositModal,
        handleDeleteContactModal,
        handleOpenContactPaymentModal,
        handleDeleteWalletContact
    };
};
