import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { apiPayBillService } from '@api';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { TCreatePayee, TSearchedPayees } from '@xeppt/xeppt-sdk/types';

interface IProps {
    handleBack: () => void;
    chosenPayee?: string;
    payeesList: TSearchedPayees[];
    form: { accountNumber: string; alias?: string; id?: string; name?: string; code?: string };
    setForm: Dispatch<
        SetStateAction<{
            accountNumber: string;
            alias?: string;
            id?: string;
            name?: string;
            code?: string;
        }>
    >;
    isUpdate: boolean;
}

const Payee: FC<IProps> = ({ handleBack, chosenPayee, payeesList, isUpdate, setForm, form }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.manage_payees'
    });
    const {
        labelLocale,
        submitLocale,
        requestErrorLocale,
        requestSuccessLocale,
        validationLocale
    } = useLocales();
    const [isError, setIsError] = useState(false);
    const { handleRequest: handleUpdatePayee, isLoading: isUpdatePayeeLoading } = useApiMutation({
        method: () => {
            return apiPayBillService
                .updatePayee(form.id as string, form as TCreatePayee)
                .then()
                .catch();
        },
        onSuccess: () => {
            handleBack();
            requestSuccessLocale('update_payee');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleCreatePayee, isLoading: isCreatePayeeLoading } = useApiMutation({
        method: () => {
            return apiPayBillService.createPayee({
                ...form,
                ...(payeesList.find((item) => item.code === chosenPayee) as TSearchedPayees)
            });
        },
        onSuccess: () => {
            setForm({
                alias: '',
                name: '',
                accountNumber: ''
            });
            handleBack();
            requestSuccessLocale('create_payee');
        },
        onError: requestErrorLocale
    });

    const handleAddPayee = () => {
        if (form.accountNumber) {
            setIsError(false);
            if (isUpdate && form.id && form.name && form.code) {
                handleUpdatePayee(undefined);
            }
            if (!isUpdate) {
                handleCreatePayee(undefined);
            }
        } else {
            setIsError(true);
        }
    };

    return (
        <>
            {chosenPayee && (
                <motion.div {...pageAnimation} className={styles.content}>
                    <div
                        className={styles.form_row}
                        style={{ alignItems: 'flex-start', marginBottom: 24 }}>
                        <Input
                            full
                            label={labelLocale('account_number')}
                            helperText={
                                isError ? validationLocale('account_number') : t('help_account')
                            }
                            onlyNumbers
                            value={form.accountNumber}
                            onChange={(val) =>
                                setForm((state) => ({ ...state, accountNumber: val }))
                            }
                            error={isError}
                        />
                        <Input
                            full
                            label={t('name_label')}
                            helperText={t('helper_name')}
                            value={form.alias}
                            onChange={(val) => setForm((state) => ({ ...state, alias: val }))}
                        />
                    </div>
                </motion.div>
            )}
            <div className={styles.actions}>
                <Button
                    leftIcon="arrow_left"
                    variant="outlined-dark"
                    size="normal"
                    onClick={() => {
                        handleBack();
                        setForm({ accountNumber: '', alias: '' });
                    }}>
                    {submitLocale('back')}
                </Button>
                <Button
                    variant="primary"
                    size="normal"
                    onClick={handleAddPayee}
                    isLoading={isCreatePayeeLoading || isUpdatePayeeLoading}>
                    {submitLocale(isUpdate ? 'update_payee' : 'add_payee')}
                </Button>
            </div>
        </>
    );
};

export default Payee;
