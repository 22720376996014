import React, { FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    children: ReactNode;
    variant?: 'PENDING' | 'CONFIRMED' | 'READY' | 'PAID' | 'REDEEMED';
    className?: string;
}

const cx = classNames.bind(styles);

const CashbackStatus: FC<IProps> = ({ children, variant, className }) => {
    const rootStyles = useMemo(
        () => cx([styles.wrapper, className, variant]),
        [className, variant]
    );

    return <div className={rootStyles}>{children}</div>;
};

export default CashbackStatus;
