import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';

interface INotFoundProps {
    notFoundCallback?: () => void;
    callback?: () => void;
    isNotification?: boolean;
    isRedirectNotFound?: boolean;
    isRedirectBack?: boolean;
    error?: {
        statusCode?: number;
    };
}

export const useNotFound = () => {
    const navigate = useNavigate();
    const { requestErrorLocale } = useLocales();
    const { user } = useUserContext();

    const handleNotFound = ({
        error,
        isNotification,
        notFoundCallback,
        callback,
        isRedirectNotFound = true,
        isRedirectBack
    }: INotFoundProps) => {
        if (error?.statusCode === 404) {
            !isRedirectBack && isRedirectNotFound && navigate(routes.not_found);
            notFoundCallback && notFoundCallback();
        }
        if (!isRedirectNotFound && isRedirectBack && user) {
            if (window.history.length > 1) {
                navigate(-1);
            } else {
                navigate(routes.dashboard);
            }
        }
        callback && callback();
        isNotification && requestErrorLocale(error);
    };

    return {
        handleNotFound
    };
};
