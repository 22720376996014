import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';

export const useCashbackModals = () => {
    const { onOpen } = useModalContext();

    const handleOpenCashbackModal = () => {
        onOpen({
            modalId: modalIds.CASHBACK_MODAL
        });
    };

    return {
        handleOpenCashbackModal
    };
};
