import React, { FC } from 'react';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import Table from '@components/table';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useFormContext, useWatch } from 'react-hook-form';
import Pagination from '@components/pagination';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { formatDateForMoment } from '@utils/date';
import { TPayee } from '@xeppt/xeppt-sdk/types';

interface IProps {
    onNextStep: () => void;
    onPageChange: (page: number) => void;
    pagesCount: number;
    listPayees: TPayee[];
    pageSize: number;
    onPageSizeChange: (val: number) => void;
}

const SelectPayee: FC<IProps> = ({
    onNextStep,
    listPayees,
    onPageChange,
    pagesCount,
    onPageSizeChange,
    pageSize
}) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.pay_bills.select_payee'
    });
    const { submitLocale } = useLocales();
    const navigate = useNavigate();
    const form = useFormContext();
    const watchedPayee = useWatch({ name: 'payeeId', control: form.control });

    const columns = [
        {
            label: t('payee'),
            key: 'name',
            render: (_: string, data: TPayee) => (
                <div style={{ width: '100%' }}>
                    {data.name} - {data.accountNumber}
                    <br />
                    {data?.alias && `${data?.alias}`}
                </div>
            )
        },
        {
            label: t('last_payment'),
            key: 'lastPayment',
            isRightAlignment: true,
            render: (value: string) =>
                value ? moment(formatDateForMoment(value)).format('MM/DD/YYYY') : '-'
        }
    ];

    return (
        <>
            <Button
                variant="outlined-dark"
                size="small"
                className={styles.new_contact}
                onClick={() => navigate(routes.pay_bills_payees)}>
                {t('add_payee')}
            </Button>
            <motion.div {...pageAnimation} className={styles.content}>
                <Table
                    columns={columns}
                    rows={listPayees}
                    isActions
                    selectKey="id"
                    selected={watchedPayee ? [watchedPayee] : []}
                    isSingleSelect
                    emptyDescription={t('empty_payees')}
                    onSelectedChange={(value) => {
                        if (value.length > 0) {
                            form.setValue('payeeId', value[0]);
                        } else {
                            form.setValue('payeeId', undefined);
                        }
                    }}
                />
                {pagesCount > 1 && (
                    <Pagination
                        pageCount={pagesCount}
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onPageSizeChange={onPageSizeChange}
                    />
                )}
            </motion.div>
            <Button variant="primary" size="normal" onClick={onNextStep} disabled={!watchedPayee}>
                {submitLocale('next')}
            </Button>
        </>
    );
};

export default SelectPayee;
