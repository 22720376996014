import React, { FC, useState } from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import TransactionTable from '@components/transactions_table';
import { getDateMonthAgo, getDisableFeature } from '@utils/index';
import RangeDatePicker from '@components/range_date_picker';

interface IProps {
    cardId?: string;
}

const TransactionsSection: FC<IProps> = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'xeppt_card.transaction'
    });

    const { dateNow, dateMonthAgo } = getDateMonthAgo();

    const [filter, setFilter] = useState<{ dateFrom: Date | null; dateTo: Date | null }>({
        dateFrom: dateMonthAgo,
        dateTo: dateNow
    });
    // const { data: transactions, isLoading } = useApiQuery({
    //     method: () =>
    //         apiWalletService.getCardTransactions({
    //             startDate: filter.dateFrom,
    //             endDate: filter.dateTo,
    //             cardId: cardId || ''
    //         }),
    //     condition: !!cardId,
    //     deps: [filter, cardId]
    // });

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Typography variant="h4">{t('title')}</Typography>
                <div className={styles.range_date_wrapper}>
                    <RangeDatePicker
                        values={[
                            filter.dateFrom?.toString() || null,
                            filter.dateTo?.toString() || null
                        ]}
                        maxDate={getDisableFeature()}
                        onChange={(val) => {
                            setFilter({
                                dateFrom: val?.[0] ? new Date(val?.[0] as string) : null,
                                dateTo: val?.[1] ? new Date(val?.[1] as string) : null
                            });
                        }}
                        full
                    />
                </div>
            </div>
            <div className={styles.content}>
                <TransactionTable transactions={[]} isCard />
            </div>
        </div>
    );
};

export default TransactionsSection;
