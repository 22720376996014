import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';
import Typography from '@components/common/typography';
import Payee from '@sections/pay_bills/manage_payees/payee';
import PayeesTable from '@sections/pay_bills/manage_payees/payees_table';
import { useTranslation } from 'react-i18next';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiPayBillService } from '@api';
import SearchSelect from '@components/common/search_select';
import { useDebounceValue } from 'usehooks-ts';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const ManagePayeesSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.manage_payees'
    });
    const [isEdit, setIsEdit] = useState(false);
    const [chosenPayee, setChosenPayee] = useState('');
    const [search, setSearch] = useState('');
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);
    const [refetchCounter, setRefetchCounter] = useState(0);
    const [form, setForm] = useState<{
        accountNumber: string;
        alias?: string;
        id?: string;
        name?: string;
        code?: string;
    }>({
        accountNumber: '',
        alias: ''
    });
    const [isUpdate, setIsUpdate] = useState(false);

    const { data: payeesList, handleRequest: handleSearchPayee } = useApiMutation({
        method: () => apiPayBillService.searchPayees(search || '')
    });

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    useEffect(() => {
        if (requestSearch.length >= 3) {
            handleSearchPayee(undefined);
        }
    }, [requestSearch]);

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                    <div className={styles.ddr_info}>{t('description')}</div>
                    <div className={styles.form_row}>
                        <SearchSelect
                            search={search}
                            onChangeSearch={(val) => setSearch(val)}
                            onChange={(val) => {
                                setIsEdit(true);
                                setChosenPayee(val as string);
                            }}
                            value={chosenPayee}
                            full
                            label={t('search_payee')}
                            items={
                                !isUpdate
                                    ? payeesList?.map((item) => ({
                                          label: item.name,
                                          value: item.code
                                      })) || []
                                    : [{ label: form.name || '', value: form.code || '' }]
                            }
                        />
                    </div>
                </div>
                {isEdit ? (
                    <Payee
                        payeesList={payeesList || []}
                        chosenPayee={chosenPayee}
                        form={form}
                        setForm={setForm}
                        isUpdate={isUpdate}
                        handleBack={() => {
                            setRefetchCounter((state) => state + 1);
                            setIsEdit(false);
                            setIsUpdate(false);
                        }}
                    />
                ) : (
                    <PayeesTable
                        refetchDataCounter={refetchCounter}
                        onAdd={() => setIsEdit(true)}
                        onEdit={(data: {
                            accountNumber: string;
                            alias?: string;
                            id: string;
                            name: string;
                            code: string;
                        }) => {
                            setForm(data);
                            setChosenPayee(data.code);
                            setIsEdit(true);
                            setIsUpdate(true);
                        }}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default ManagePayeesSection;
