import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import AmountSection from './amount';
import SummarySection from './summary';
import SuccessSection from './success';
import Button from '@components/common/button';
import useModalContext from '@hooks/context/useModalContext';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiAccountService } from '@api';

const CashbackModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    const { submitLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const { onClose, handleChangeLoading } = useModalContext();
    const [step, setStep] = useState(1);
    const { refetchCashback } = useUserContext();

    const renderContent = () => {
        switch (step) {
            case 1:
                return <AmountSection />;
            case 2:
                return <SummarySection />;
            case 3:
                return <SuccessSection />;
        }
    };

    const { handleRequest: handleRedeemNotification } = useApiMutation({
        method: () => apiAccountService.redeemCashback(),
        onSuccess: () => {
            requestSuccessLocale('redeem_cashback');
            setStep((state) => state + 1);
        },
        onError: requestErrorLocale,
        onFinish: () => {
            handleChangeLoading(false);
        }
    });

    useEffect(() => {
        if (step === 0) {
            onClose();
        }
    }, [step]);

    const onSubmit = () => {
        if (step === 1) {
            setStep((state) => state + 1);
        } else if (step === 2) {
            handleChangeLoading(true);
            handleRedeemNotification(undefined);
        }
    };

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" className={styles.title}>
                {t('title')}
            </Typography>
            {renderContent()}
            <div className={styles.actions}>
                {step === 3 && (
                    <>
                        <Button variant="primary" size="normal" onClick={onClose}>
                            {submitLocale('done')}
                        </Button>
                    </>
                )}{' '}
                {step !== 3 && (
                    <>
                        <Button
                            variant="outlined-dark"
                            size="normal"
                            onClick={() => setStep((state) => state - 1)}>
                            {submitLocale(step === 1 ? 'cancel' : 'back')}
                        </Button>
                        <Button variant="primary" size="normal" onClick={onSubmit}>
                            {submitLocale(step === 2 ? 'redeem' : 'next')}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default CashbackModal;
