import React, { FC, useEffect, useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext, useWatch } from 'react-hook-form';
import { EAddressType } from '@xeppt/xeppt-sdk/types/user';
import { getAddressFormName, getAddressName } from '@utils/index';
import Radio from '@components/common/radio';
import { defaultAddressData } from '@const/default_form_data';
import FullAddress from '@components/address';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { apiRegistrationService } from '@api';
import { TRegisterBusinessUser } from '@xeppt/xeppt-sdk/types';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { Country } from 'country-state-city';
import { useApiMutation } from '@hooks/api/useApiMutation';
import moment from 'moment-timezone';

interface IProps {
    type: EAddressType;
    onChange: (val: EAddressType) => void;
}

const BusinessAddressSection: FC<IProps> = ({ type, onChange }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.business_address'
    });
    const { labelLocale, submitLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const [isHidden, setIsHidden] = useState(false);
    const { nextStep, prevStep } = useSignupContext();
    const {
        control,
        formState: { errors },
        setValue,
        getValues
    } = useFormContext();
    const { handleRequest: onCreate, isLoading } = useApiMutation({
        method: () => {
            const values = getValues();
            return apiRegistrationService.registerBusiness({
                ...values,
                profile: {
                    ...values.profile,
                    timezone: moment.tz.guess()
                },
                tradingAddress: {
                    ...values.tradingAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.tradingAddress.country
                        )?.isoCode || values.tradingAddress.country
                },
                businessAddress: {
                    ...values.businessAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.businessAddress.country
                        )?.isoCode || values.businessAddress.country
                },
                registrationAddress: {
                    ...values.registrationAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.registrationAddress.country
                        )?.isoCode || values.registrationAddress.country
                },
                billingAddress: {
                    ...values.billingAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.billingAddress.country
                        )?.isoCode || values.billingAddress.country
                }
            } as TRegisterBusinessUser);
        },
        onSuccess: () => {
            requestSuccessLocale('registration');
            nextStep();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        if (type === EAddressType.BUSINESS) {
            onChange(EAddressType.REGISTRATION);
        } else if (type === EAddressType.REGISTRATION) {
            onChange(EAddressType.BILLING);
        } else if (type === EAddressType.BILLING) {
            onChange(EAddressType.TRADING);
        } else {
            onCreate(undefined);
        }
    };

    const onBack = () => {
        if (type === EAddressType.BUSINESS) {
            prevStep();
        } else if (type === EAddressType.REGISTRATION) {
            onChange(EAddressType.BUSINESS);
        } else if (type === EAddressType.BILLING) {
            onChange(EAddressType.REGISTRATION);
        } else {
            onChange(EAddressType.BILLING);
        }
    };

    const businessAddress = useWatch({ name: 'businessAddress', control });
    const registrationAddress = useWatch({
        name: 'registrationAddress',
        control
    });
    const tradingAddress = useWatch({ name: 'tradingAddress', control });
    const billingAddress = useWatch({ name: 'billingAddress', control });

    const registrationSameBusiness = useWatch({
        name: 'registrationSameBusiness',
        control
    });
    const tradingSameBusiness = useWatch({
        name: 'tradingSameBusiness',
        control
    });
    const billingSameBusiness = useWatch({
        name: 'billingSameBusiness',
        control
    });

    const getAddressValue = () => {
        if (type === EAddressType.BUSINESS) {
            return businessAddress;
        } else if (type === EAddressType.REGISTRATION) {
            return registrationAddress;
        } else if (type === EAddressType.BILLING) {
            return billingAddress;
        } else {
            return tradingAddress;
        }
    };
    const getIsSameValue = () => {
        if (type === EAddressType.REGISTRATION) {
            return registrationSameBusiness;
        } else if (type === EAddressType.TRADING) {
            return tradingSameBusiness;
        } else if (type === EAddressType.BILLING) {
            return billingSameBusiness;
        }
    };
    const handleChangeIsSame = (value: boolean) => {
        setIsHidden(true);
        setTimeout(() => {
            setIsHidden(false);
        }, 0);
        if (type === EAddressType.REGISTRATION) {
            if (value) {
                setValue('registrationAddress', businessAddress);
            } else {
                setValue('registrationAddress', defaultAddressData);
            }
            setValue('registrationSameBusiness', value);
        } else if (type === EAddressType.TRADING) {
            if (value) {
                setValue('tradingAddress', businessAddress);
            } else {
                setValue('tradingAddress', defaultAddressData);
            }
            setValue('tradingSameBusiness', value);
        } else if (type === EAddressType.BILLING) {
            if (value) {
                setValue('billingAddress', businessAddress);
            } else {
                setValue('billingAddress', defaultAddressData);
            }
            setValue('billingSameBusiness', value);
        }
    };

    useEffect(() => {
        setIsHidden(true);
        setTimeout(() => {
            setIsHidden(false);
        }, 0);
        if (!getAddressValue()?.address1) {
            handleChangeIsSame(false);
        }
    }, [type]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography
                className={type === EAddressType.BUSINESS ? styles.description : styles.title}
                variant="h3">
                {t('title')} {getAddressName(type, t)}
            </Typography>
            {type !== EAddressType.BUSINESS && (
                <Typography className={styles.description} variant="body2">
                    {t('description_1')} {getAddressName(type, t)} {t('description_2')}
                </Typography>
            )}
            {type !== EAddressType.BUSINESS && (
                <>
                    <div className={styles.radio_row}>
                        <Typography variant="body3">
                            {t('same_business_1')} {getAddressName(type, t)} {t('same_business_2')}
                        </Typography>
                        <Radio
                            checked={getIsSameValue()}
                            onClick={() => handleChangeIsSame(true)}
                        />
                    </div>
                    <div className={styles.same_address}>
                        <Typography variant="body3">
                            <b>{labelLocale('country')}:</b> {businessAddress?.country}
                        </Typography>
                        <Typography variant="body3">
                            <b>{labelLocale('city')}:</b> {businessAddress?.city}
                        </Typography>
                        <Typography variant="body3">
                            <b>{labelLocale('address_1')}:</b> {businessAddress?.address1}
                        </Typography>
                        <Typography variant="body3">
                            <b>{labelLocale('address_2')}:</b> {businessAddress?.streetName}
                        </Typography>
                        <Typography variant="body3">
                            <b>{labelLocale('region')}:</b> {businessAddress?.region}
                        </Typography>
                        <Typography variant="body3">
                            <b>{labelLocale('zipCode')}:</b> {businessAddress?.zipCode}
                        </Typography>
                    </div>
                    <div className={styles.radio_row}>
                        <Typography variant="body3">
                            {t('add_another')} {getAddressName(type, t)}
                        </Typography>
                        <Radio
                            checked={!getIsSameValue()}
                            onClick={() => handleChangeIsSame(false)}
                        />
                    </div>
                </>
            )}
            <div className={styles.form}>
                {!isHidden && <FullAddress name={getAddressFormName(type)} />}
                <div className={styles.actions}>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        variant="outlined-dark"
                        size="normal"
                        onClick={onBack}>
                        {submitLocale('back')}
                    </Button>
                    <Button
                        disabled={Object.keys(errors)?.length !== 0 || false}
                        className={styles.next}
                        onClick={onSubmit}
                        size="normal"
                        isLoading={isLoading}
                        variant="primary">
                        Next
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default BusinessAddressSection;
