import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import Status from '@components/common/status';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { currencies } from '@const/currency';
import { getTransactionStatus, prettifyAmount } from '@utils/index';
import Skeleton from '@components/common/skeleton';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ETransactionAction, ETransactionMethod, ETransactionStatus } from '@xeppt/xeppt-sdk/types';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { ENewNotificationType } from '@enum';
import { moneyInTransactions, moneyOutTransactions } from '@const/transactions';
import { Icon } from '@components/icons';

const TransactionDetailsLayout = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('transaction_details');
    const { id } = useParams<{ id: string }>();
    const { refetchAccount } = useUserContext();
    const { handleNotFound } = useNotFound();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const {
        data: transaction,
        isLoading: isTransactionLoading,
        handleRequest: refetchTransaction
    } = useApiQuery({
        method: () => apiAccountService.getTransactionById(id as string),
        onError: (error) => {
            handleNotFound({
                error,
                isNotification: true
            });
        },
        deps: [id],
        notificationType: ENewNotificationType.TRANSACTION
    });

    const { handleRequest: handleApproveTransaction, isLoading: isApproveLoading } = useApiMutation(
        {
            method: () => apiAccountService.approveRequestMoneyWallet(id as string),
            onSuccess: () => {
                refetchTransaction();
                requestSuccessLocale('approve_transaction');
                refetchAccount();
            },
            onError: requestErrorLocale
        }
    );

    const { handleRequest: handleDeclineTransaction, isLoading: isDeclineLoading } = useApiMutation(
        {
            method: () => apiAccountService.declineRequestMoneyWallet(id as string, ''),
            onSuccess: () => {
                refetchTransaction();
                requestSuccessLocale('cancel_transaction');
                refetchAccount();
            },
            onError: requestErrorLocale
        }
    );

    const { handleRequest: handleCancelTransaction, isLoading: isCancelLoading } = useApiMutation({
        method: () => apiAccountService.cancelRequestMoneyWallet(id as string),
        onSuccess: () => {
            refetchTransaction();
            requestSuccessLocale('cancel_transaction');
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleResendNotifiaction, isLoading: isResendLoading } = useApiMutation({
        method: () => apiAccountService.resendRequestMoneyNotification(id as string),
        onSuccess: () => {
            refetchTransaction();
            requestSuccessLocale('resend_transaction');
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const isTransactionRequest = useMemo(
        () =>
            (transaction?.action === ETransactionAction.REQUEST_MONEY ||
                transaction?.action === ETransactionAction.INCOMING_REQUEST_MONEY) &&
            transaction?.status === ETransactionStatus.PENDING,
        [transaction]
    );

    const isTransactionRequestedToMe = useMemo(
        () =>
            transaction?.action === ETransactionAction.INCOMING_REQUEST_MONEY &&
            transaction?.status === ETransactionStatus.PENDING,
        [transaction]
    );

    const isTransactionRequestedFromMe = useMemo(
        () =>
            transaction?.action === ETransactionAction.REQUEST_MONEY &&
            transaction?.status === ETransactionStatus.PENDING,
        [transaction]
    );

    const isInternalTransactionRequest = useMemo(
        () => isTransactionRequest && transaction?.method === ETransactionMethod.WALLET,
        [transaction]
    );

    const isNotMessage = useMemo(() => {
        if (transaction) {
            const actions = [
                ETransactionAction.TRANSACTION_FEE,
                ETransactionAction.LOAD_CARD_BALANCE,
                ETransactionAction.BILL_PAYMENT
            ];
            const methods = [ETransactionMethod.EFT];
            return actions.includes(transaction?.action) || methods.includes(transaction.method);
        } else return false;
    }, [transaction]);

    const isShowDestination = useMemo(() => {
        if (transaction) {
            return (
                moneyOutTransactions.includes(transaction?.action) ||
                transaction?.action === ETransactionAction.LOAD_CARD_BALANCE
            );
        }
    }, [transaction]);

    const { handleRequest: handleBlockContact } = useApiMutation({
        method: (val: string) => {
            return apiAccountService.blockMoneyRequester(val);
        },
        onSuccess: () => {
            requestSuccessLocale('block_contact');
        },
        onError: requestErrorLocale
    });

    if (!transaction) return null;

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.head}>
                <Button onClick={() => navigate(-1)} leftIcon="arrow_left">
                    {t('summary')}
                </Button>
            </div>
            {isTransactionLoading ? (
                <Skeleton className={styles.skeleton} />
            ) : (
                <div className={styles.content_wrapper}>
                    <Typography variant="body1" weight="bold">
                        {t('details')}
                    </Typography>
                    <div className={styles.content}>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('transaction_id')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.id}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('amount')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {currencies[transaction?.currency as 'CAD']?.symbol}{' '}
                                {prettifyAmount(transaction?.amount?.toFixed(2) || '0')}{' '}
                                {transaction?.currency}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('transaction_type')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.method &&
                                    enumTranslate[
                                        transaction.action === ETransactionAction.SEND_MONEY &&
                                        transaction.method === ETransactionMethod.EFT
                                            ? 'to_bank'
                                            : transaction.method
                                    ][i18n.language as 'en' | 'fr']}
                            </Typography>
                        </div>
                        {transaction.method === ETransactionMethod.E_TRANSFER &&
                            !(transaction.action === ETransactionAction.LOAD_CARD_BALANCE) && (
                                <div className={styles.item}>
                                    <Typography variant="body3">{t('reference')}</Typography>
                                    <Typography weight="bold" variant="body3">
                                        {/*//@ts-ignore*/}
                                        {transaction?.details?.etransferReferenceNumber}
                                    </Typography>
                                </div>
                            )}
                        {/*//@ts-ignore*/}
                        {!isNotMessage && transaction?.details?.message && (
                            <div className={styles.item}>
                                <Typography variant="body3">{t('message')}</Typography>
                                <Typography weight="bold" variant="body3">
                                    {/*//@ts-ignore*/}
                                    {transaction?.details?.message || '-'}
                                </Typography>
                            </div>
                        )}
                        {isShowDestination && (
                            <div className={styles.item}>
                                <Typography variant="body3">{t('destination')}</Typography>
                                <div>
                                    <Typography weight="bold" variant="body3">
                                        {transaction?.destination}
                                    </Typography>
                                    {transaction.action ===
                                        ETransactionAction.INCOMING_REQUEST_MONEY &&
                                        transaction.status === ETransactionStatus.PENDING && (
                                            <button
                                                className={styles.block_requester}
                                                onClick={() =>
                                                    //@ts-ignore
                                                    handleBlockContact(transaction?.id)
                                                }>
                                                <Icon name="block" />
                                                Block
                                            </button>
                                        )}
                                </div>
                            </div>
                        )}
                        {moneyInTransactions.includes(transaction?.action) &&
                            transaction.action !== ETransactionAction.LOAD_CARD_BALANCE && (
                                <div className={styles.item}>
                                    <Typography variant="body3">{t('source')}</Typography>
                                    <Typography weight="bold" variant="body3">
                                        {transaction?.source}
                                    </Typography>
                                </div>
                            )}
                        <div className={styles.item}>
                            <Typography variant="body3">{t('date')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {moment(transaction?.createdAt).format('MM/DD/YYYY HH:mm')}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('status')}</Typography>
                            <Status variant={getTransactionStatus(transaction?.status)}>
                                {transaction?.status &&
                                    ETransactionStatusTranslate[transaction.status][
                                        i18n.language as 'en' | 'fr'
                                    ]}
                            </Status>
                        </div>
                        {isTransactionRequestedToMe && (
                            <div className={styles.item}>
                                <Typography variant="body3">From</Typography>
                                <Typography weight="bold" variant="body3">
                                    {transaction?.consumerName}
                                </Typography>
                            </div>
                        )}
                    </div>
                    <div className={styles.actions}>
                        {isTransactionRequestedToMe && isInternalTransactionRequest && (
                            <Button
                                className={styles.button}
                                variant="primary"
                                size="small"
                                isLoading={isApproveLoading}
                                onClick={handleApproveTransaction}>
                                Approve request
                            </Button>
                        )}
                        {isTransactionRequestedToMe && isInternalTransactionRequest && (
                            <Button
                                className={styles.button}
                                variant="outlined-dark"
                                size="small"
                                isLoading={isDeclineLoading}
                                onClick={handleDeclineTransaction}>
                                Decline request
                            </Button>
                        )}
                        {/*{isTransactionRequestedToMe && (*/}
                        {/*    <Button*/}
                        {/*        className={styles.button}*/}
                        {/*        variant="outlined-dark"*/}
                        {/*        size="small"*/}
                        {/*        // isLoading={isDeclineLoading}*/}
                        {/*        // onClick={handleDeclineTransaction}*/}
                        {/*    >*/}
                        {/*        Report*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                        {isTransactionRequestedFromMe && isInternalTransactionRequest && (
                            <Button
                                className={styles.button}
                                variant="outlined-dark"
                                size="small"
                                isLoading={isCancelLoading}
                                onClick={handleCancelTransaction}>
                                Cancel
                            </Button>
                        )}
                        {isTransactionRequestedFromMe && isInternalTransactionRequest && (
                            <Button
                                className={styles.button}
                                variant="primary"
                                size="small"
                                isLoading={isResendLoading}
                                onClick={handleResendNotifiaction}>
                                Send reminder
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default TransactionDetailsLayout;
