import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiPayBillService } from '@api';
import Pagination from '@components/pagination';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { formatDateForMoment } from '@utils/date';
import Info from '@components/common/info';
import { TPayee } from '@xeppt/xeppt-sdk/types';
import { usePayBillsModal } from '@hooks/modals/usePayBillsModal';

interface IProps {
    onAdd: () => void;
    refetchDataCounter: number;
    onEdit: (data: {
        accountNumber: string;
        alias?: string;
        id: string;
        name: string;
        code: string;
    }) => void;
}

const PayeesTable: FC<IProps> = ({ refetchDataCounter, onEdit }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.manage_payees'
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { handleDeletePayeeModal } = usePayBillsModal();

    const { data: listPayees, handleRequest: refetchPayeesList } = useApiQuery({
        method: () => apiPayBillService.listPayees(pageSize, currentPage),
        deps: [currentPage, pageSize]
    });

    useEffect(() => {
        if (refetchDataCounter > 0) {
            refetchPayeesList();
        }
    }, [refetchDataCounter]);

    const columns = [
        {
            label: t('payee'),
            key: 'name',
            render: (_: string, data: TPayee) => (
                <div style={{ width: '100%' }}>
                    {data.name} - {data.accountNumber}
                    <br />
                    {data?.alias && `${data?.alias}`}
                </div>
            ),
            width: 350
        },
        {
            label: t('last_payment'),
            key: 'lastPayment',
            render: (value?: string) =>
                value ? moment(formatDateForMoment(value)).format('MM/DD/YYYY') : '-'
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: undefined, data: TPayee) => (
                <div className={styles.table_actions}>
                    <Info maxWidth variant="left" content="Edit">
                        <Button
                            leftIcon="edit"
                            onClick={() =>
                                onEdit({
                                    alias: data?.alias,
                                    name: data.name,
                                    id: data.id,
                                    code: data.code,
                                    accountNumber: data.accountNumber
                                })
                            }
                        />
                    </Info>
                    <Info maxWidth variant="left" content="Delete">
                        <Button
                            leftIcon="trash"
                            className={styles.delete}
                            onClick={() => handleDeletePayeeModal(data.id, refetchPayeesList)}
                        />
                    </Info>
                </div>
            )
        }
    ];

    return (
        <motion.div {...pageAnimation} className={styles.content}>
            <Table
                columns={columns}
                rows={listPayees?.data || []}
                isActions
                emptyDescription={t('empty_payees')}
            />
            {(listPayees?.pagesTotal || 0) > 1 && (
                <Pagination
                    pageCount={listPayees?.pagesTotal || 0}
                    onPageChange={(page) => setCurrentPage(page)}
                    pageSize={pageSize}
                    onPageSizeChange={(val) => setPageSize(val)}
                />
            )}
        </motion.div>
    );
};

export default PayeesTable;
