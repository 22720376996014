import React from 'react';
//@ts-ignore
import Flag from 'react-world-flags';

interface FlagProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    name: string;
    width?: number;
    height?: number;
}

const FlagIcon: React.FC<FlagProps> = ({ name, width, height, ...props }) => {
    return <Flag code={name} style={{ width, height, borderRadius: 6 }} {...props} />;
};

export default FlagIcon;
