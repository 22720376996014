import React from 'react';
import AccountsSection from '@sections/dashboard/accounts';
import styles from './styles.module.scss';
import BalanceSection from '@sections/dashboard/balance';
import CardsSection from '@sections/dashboard/cards';
import TransactionsSection from '@sections/dashboard/transactions';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Cashback from '@sections/dashboard/cashback';

const DashboardLayout = () => {
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.left}>
                <BalanceSection />
                <CardsSection />
                <TransactionsSection />
            </div>
            <div className={styles.right}>
                <AccountsSection />
                <Cashback />
            </div>
        </motion.div>
    );
};

export default DashboardLayout;
