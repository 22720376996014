import React from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';

const SummarySection = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    const { user, cashback } = useUserContext();
    return (
        <>
            <div className={styles.note}>
                <Icon name="error_filled" width={18} height={18} />
                {t('note_2_redeem')}
            </div>
            <div className={styles.summary}>
                <Typography className={styles.title}>{t('summary')}</Typography>
                <div className={styles.summary_line}>
                    <div>{t('amount_summary')}</div>
                    <div>${cashback?.available?.toFixed(2)} CAD</div>
                </div>
                <div className={styles.summary_line}>
                    <div>{t('to')}</div>
                    <div>
                        {t('redeem_to')} ({user?.email})
                    </div>
                </div>
            </div>
        </>
    );
};

export default SummarySection;
