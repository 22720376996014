import { modalIds } from '@const/modals';
import { apiContactService } from '@api';
import { routes } from '@const/routes';
import useModalContext from '@hooks/context/useModalContext';
import { useNavigate } from 'react-router-dom';
import { useLocales } from '@hooks/helpers/useLocales';

export const usePayBillsModal = () => {
    const { onOpen, onClose, handleChangeLoading } = useModalContext();
    const navigate = useNavigate();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const handleDeletePayeeModal = (id: string, refetchContacts: () => void) => {
        onOpen({
            modalId: modalIds.DELETE_WALLET_CONTACT_MODAL,
            onSubmit: () => {
                handleChangeLoading(true);
                apiContactService
                    .deleteContact(id)
                    .then(() => {
                        requestSuccessLocale('delete_payee');
                        refetchContacts();
                        navigate(routes.pay_bills_payees);
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    return { handleDeletePayeeModal };
};
