import { z } from 'zod';
import { getPhoneValidation } from '@helpers/validation_objects/index';
import { validator } from '@xeppt/xeppt-sdk';
import { TAddress } from '@types';
import { EAddressType } from '@xeppt/xeppt-sdk/types/user';

interface IPersonalValidationProps {
    currentStep: number;
    phoneVerification: boolean;
    isEmailSent: boolean;
    messages: {
        email: string;
        phone: string;
        phoneVerifyCode: string;
        firstName: string;
        lastName: string;
        birthDate: string;
        occupancy: string;
        billingAddress: TAddress;
        password: string;
        passwordConfirm: string;
    };
}

export const signupPersonalStepsValidation = ({
    currentStep,
    messages,
    phoneVerification,
    isEmailSent
}: IPersonalValidationProps) => {
    const steps = [
        z.object({}),
        z.object({
            email: validator.email(messages.email),
            ...(isEmailSent && { code: z.string().length(6) })
        }),
        // z.object({}),
        getPhoneValidation(
            { phone: messages.phone, verification: messages.phoneVerifyCode },
            phoneVerification
        ),
        z
            .object({
                password: validator.password(messages.password),
                passwordConfirm: validator.password(messages.passwordConfirm)
            })
            .refine((data) => data.password === data.passwordConfirm, {
                message: messages.passwordConfirm,
                path: ['passwordConfirm']
            }),
        z.object({
            profile: z.object({
                firstName: validator.firstName(messages.firstName),
                lastName: validator.lastName(messages.lastName),
                birthday: z.string().min(1, messages.birthDate),
                occupancy: z.string().min(1, messages.occupancy)
            })
        }),
        z.object({
            billingAddress: validator.address(messages.billingAddress)
        })
    ];
    return steps[currentStep];
};

interface IBusinessValidationProps {
    addressType: EAddressType;
    currentStep: number;
    phoneVerification: boolean;
    isEmailSent: boolean;
    messages: {
        email: string;
        phone: string;
        phoneVerifyCode: string;
        firstName: string;
        lastName: string;
        birthDate: string;
        address: TAddress;
        password: string;
        passwordConfirm: string;
        residence: string;
        occupancy: string;
        business: {
            companyName: string;
            tradingName: string;
            registrationNumber: string;
            category: string;
            size: string;
            website: string;
        };
    };
}

const getAddressValidation = (type: EAddressType, messages: TAddress) => {
    if (type === EAddressType.BUSINESS) {
        return z.object({
            businessAddress: validator.address(messages)
        });
    } else if (type === EAddressType.TRADING) {
        return z.object({
            tradingAddress: validator.address(messages)
        });
    } else if (type === EAddressType.BILLING) {
        return z.object({
            billingAddress: validator.address(messages)
        });
    } else {
        return z.object({
            registrationAddress: validator.address(messages)
        });
    }
};

export const signupBusinessStepsValidation = ({
    addressType,
    currentStep,
    messages,
    phoneVerification,
    isEmailSent
}: IBusinessValidationProps) => {
    const steps = [
        z.object({}),
        z.object({
            email: validator.email(messages.email),
            ...(isEmailSent && { code: z.string().length(6) })
        }),
        getPhoneValidation(
            { phone: messages.phone, verification: messages.phoneVerifyCode },
            phoneVerification
        ),
        // z.object({}),
        z
            .object({
                password: validator.password(messages.password),
                passwordConfirm: validator.password(messages.passwordConfirm)
            })
            .refine((data) => data.password === data.passwordConfirm, {
                message: messages.passwordConfirm,
                path: ['passwordConfirm']
            }),
        z.object({
            profile: z.object({
                firstName: validator.firstName(messages.firstName),
                lastName: validator.lastName(messages.lastName),
                birthday: validator.birthday(messages.birthDate),
                occupancy: z.string().min(1, messages.occupancy),
                residence: z.string().min(1, messages.residence)
            })
        }),
        z.object({
            business: z.object({
                companyName: z.string().min(1, { message: messages.business.companyName }),
                tradingName: z.string().min(1, { message: messages.business.tradingName }),
                registrationNumber: z
                    .string()
                    .min(1, { message: messages.business.registrationNumber }),
                category: z.string().min(1, { message: messages.business.category }),
                size: z.string().min(1, { message: messages.business.size }),
                website: z.string().url({ message: messages.business.website })
            })
        }),
        getAddressValidation(addressType, messages.address)
    ];
    return steps[currentStep];
};
