import React, { FC, SVGAttributes } from 'react';

const Sync: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.58327 22.4607C2.10638 19.6257 1.64451 16.3706 2.27448 13.2367C2.90446 10.1028 4.5883 7.27905 7.04596 5.23503C9.50362 3.191 12.587 2.04993 15.7832 2.0016C18.9794 1.95327 22.0958 3.00058 24.6142 4.96936L19.2316 7.38938M13.849 24.6137L7.21761 26.8959C9.82184 28.9976 13.093 30.0965 16.4379 29.9933C19.7828 29.8902 22.98 28.5919 25.4498 26.3338C27.9196 24.0757 29.4985 21.0073 29.9002 17.685C30.3018 14.3627 29.4997 11.0064 27.6392 8.22476"
                stroke="currentColor"
                strokeWidth="2.15304"
            />
        </svg>
    );
};

export default Sync;
