import React, { FC, useEffect, useState } from 'react';
import Table from '@components/table';
import { TTransactionLite } from '@xeppt/xeppt-sdk/types/transaction';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { ENewNotificationType } from '@enum';
import styles from '@layouts/cashback/styles.module.scss';
import moment from 'moment-timezone';
import {
    ETransactionAction,
    ETransactionStatus,
    TCashbackFilter,
    TTransaction
} from '@xeppt/xeppt-sdk/types';
import Status from '@components/common/status';
import { getTransactionStatus } from '@utils/index';
import { ETransactionStatusTranslate } from '@locales/index';
import { useTranslation } from 'react-i18next';

interface IProps {
    dateFrom?: Date;
    dateTo?: Date;
}

const WithdrawnTable: FC<IProps> = ({ dateTo, dateFrom }) => {
    const { i18n } = useTranslation('cashback');
    const [cursor, setCursor] = useState<Date>();
    const [filter, setFilter] = useState<TCashbackFilter>({
        order: 'DESC',
        status: undefined
    });
    const [transactions, setTransactions] = useState<TTransactionLite[]>([]);

    const transactionColumns = [
        {
            label: 'Date & Time',
            key: 'createdAt',
            isSort: true,
            render: (val: Date) => (
                <div className={styles.date_wrapper}>
                    <span>{moment(val).format('MMM DD, YYYY')}</span>
                    <span>{moment(val).format('HH:mm')}</span>
                </div>
            )
        },
        {
            label: 'Transaction Id',
            key: 'id'
        },
        {
            label: 'Status',
            key: 'status',
            render: (val: ETransactionStatus) => (
                <Status variant={getTransactionStatus(val)}>
                    {ETransactionStatusTranslate[val][i18n.language as 'en' | 'fr']}
                </Status>
            ),
            filterOptions: [
                { label: 'Pending', value: ETransactionStatus.PENDING },
                { label: 'Confirmed', value: ETransactionStatus.APPROVED },
                { label: 'Paid', value: ETransactionStatus.ERROR },
                { label: 'Ready', value: ETransactionStatus.DECLINED },
                { label: 'Canceled', value: ETransactionStatus.CANCELED }
            ]
        },
        {
            label: 'Amount',
            key: 'amount',
            render: (val: number, item: TTransaction) => `${val} ${item.currency}`
        },
        {
            label: 'Destination',
            key: 'destination'
        }
    ];

    useEffect(() => {
        setCursor(undefined);
        setTransactions([]);
        if (dateFrom) {
            setCursor(dateFrom);
        }
        handleGetTransactions({ dateFrom, dateTo, isNew: true });
    }, [dateTo, dateFrom]);

    const { data: transactionsResponse, handleRequest: handleGetTransactions } = useApiQuery({
        isInitialRequest: false,
        method: (request: any) => {
            return apiAccountService.findTransactions(
                {
                    order: request?.order,
                    dateTo: request?.dateTo,
                    dateFrom: request?.dateFrom,
                    action: [ETransactionAction.CASHBACK]
                },
                {
                    cursor: request?.isNew ? request?.dateFrom : cursor,
                    limit: 10
                }
            );
        },
        onSuccess: (data) => {
            if (cursor !== data.cursor) {
                setTransactions((state) => [...state, ...data.data]);
                setCursor(data.cursor);
            }
        },
        notificationType: ENewNotificationType.TRANSACTION
    });

    return (
        <Table
            //@ts-ignore
            columns={transactionColumns}
            rows={transactions}
            emptyDescription="No transactions yet"
            totalRows={transactionsResponse?.total}
            onLoadData={() => {
                handleGetTransactions();
            }}
            //@ts-ignore
            filter={{ status: filter.status }}
            //@ts-ignore
            sort={{ key: 'createdAt', value: filter.order }}
            onSortChange={(_, value) => {
                setFilter((state) => ({
                    ...state,
                    order: value.toUpperCase() as 'ASC' | 'DESC'
                }));
                setCursor(undefined);
                handleGetTransactions({
                    isNew: false,
                    ...filter,
                    order: value.toUpperCase() as 'ASC' | 'DESC'
                });
            }}
            onFilterChange={(val) => {
                setFilter((state) => ({
                    ...state,
                    ...val
                }));
                setCursor(undefined);
                handleGetTransactions({
                    isNew: false,
                    ...filter,
                    ...val
                });
            }}
        />
    );
};

export default WithdrawnTable;
