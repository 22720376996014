import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    checked?: boolean;
    onClick?: () => void;
    large?: boolean;
    small?: boolean;
    className?: string;
    disabled?: boolean;
    black?: boolean;
}

const cx = classNames.bind(styles);
const Radio: FC<IProps> = ({ large, disabled, black, small, checked, onClick, className }) => {
    const rootStyles = useMemo(
        () =>
            cx([
                styles.root,
                { isChecked: checked, isDisabled: disabled, large, small, black },
                className
            ]),
        [checked, disabled, className, large, small, black]
    );

    return (
        <label className={rootStyles} onChange={onClick}>
            <span>
                <i />
            </span>
            <input type="radio" onChange={() => {}} disabled={disabled} checked={checked} />
        </label>
    );
};

export default Radio;
