import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { EActiveETransfer } from '@enum';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import SendMoneySection from '@sections/send_and_request/send_money';
import RequestMoneySection from '@sections/send_and_request/request_money';
import ManageAutodepositSection from '@sections/send_and_request/manage_autodeposit';
import ManageContactsSection from '@sections/send_and_request/manage_contacts';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation, slideAnimation } from '@const/animation';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { useUserContext } from '@hooks/context/useUserContext';
import useResponsive from '@hooks/helpers/useResponsive';
import SchedulePaymentsSection from '@sections/send_and_request/schedule_payments';

const cx = classNames.bind(styles);

interface IProps {
    activeTab?: EActiveETransfer;
}

const SendAndRequestLayout: FC<IProps> = ({ activeTab }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('send_and_request');
    const { verifiedAction } = useVerificationGuard();
    const [currentTab, setCurrentTab] = useState<EActiveETransfer>();
    const { user } = useUserContext();
    const { isTablet, isMobile } = useResponsive();
    const [isTabletOpen, setIsTabletOpen] = useState(true);
    const isLessThenTablet = useMemo(() => isTablet || isMobile, [isTablet, isMobile]);

    const contentStyle = useMemo(
        () => cx([styles.content_wrapper, { isOpen: !!currentTab }]),
        [currentTab]
    );
    const sidebarStyle = useMemo(
        () =>
            cx([
                styles.sidebar,
                { isOpen: !!currentTab, isTabletOpen: isTabletOpen && !!currentTab }
            ]),
        [currentTab, isTabletOpen]
    );

    const toggleMobileMenu = () => {
        setIsTabletOpen((state) => !state);
    };

    useEffect(() => {
        setIsTabletOpen(!isLessThenTablet || !currentTab);
    }, [isLessThenTablet, currentTab]);

    const renderTab = () => {
        switch (activeTab) {
            case EActiveETransfer.SEND:
                return <SendMoneySection />;
            case EActiveETransfer.REQUEST:
                return <RequestMoneySection />;
            case EActiveETransfer.CONTACTS:
                return <ManageContactsSection />;
            case EActiveETransfer.AUTODEPOSIT:
                return <ManageAutodepositSection />;
            case EActiveETransfer.SCHEDULE_PAYMENTS:
                return <SchedulePaymentsSection />;
        }
    };

    useEffect(() => {
        if (activeTab) {
            verifiedAction(
                () => {
                    setCurrentTab(activeTab);
                },
                () => {
                    setCurrentTab(undefined);
                    navigate(routes.send_and_request);
                }
            );
        } else {
            setCurrentTab(undefined);
        }
    }, [activeTab, user]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={contentStyle}>
                <div className={styles.content}>{renderTab()}</div>
            </div>
            <div className={sidebarStyle}>
                <div className={cx(styles.title_wrapper, { isOpen: isTabletOpen })}>
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            <Typography variant="h4">{t('title')}</Typography>
                        </motion.div>
                    )}
                    {isLessThenTablet && !!currentTab && (
                        <button
                            className={cx([styles.burger, { isOpen: isTabletOpen }])}
                            onClick={toggleMobileMenu}>
                            <div />
                            <div />
                            <div />
                        </button>
                    )}
                </div>
                <div className={styles.divider} />
                <div
                    className={cx([styles.tab, { isActive: currentTab === EActiveETransfer.SEND }])}
                    onClick={() => navigate(routes.send_and_request_send)}>
                    <Button leftIcon="send" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('send_money')}
                        </motion.div>
                    )}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActiveETransfer.REQUEST }
                    ])}
                    onClick={() => navigate(routes.send_and_request_request)}>
                    <Button leftIcon="dollar" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('request_money')}
                        </motion.div>
                    )}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActiveETransfer.SCHEDULE_PAYMENTS }
                    ])}
                    onClick={() => navigate(routes.send_and_request_schedule)}>
                    <Button leftIcon="schedule" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('schedule_payments')}
                        </motion.div>
                    )}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActiveETransfer.CONTACTS }
                    ])}
                    onClick={() => navigate(routes.send_and_request_contacts)}>
                    <Button leftIcon="person" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('manage_contacts')}
                        </motion.div>
                    )}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActiveETransfer.AUTODEPOSIT }
                    ])}
                    onClick={() => navigate(routes.send_and_request_autodeposit)}>
                    <Button leftIcon="yen" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('manage_autodeposit')}
                        </motion.div>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

export default SendAndRequestLayout;
