import React, { FC, useState } from 'react';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';

const AmountSection = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    const { cashback } = useUserContext();
    return (
        <>
            <div className={styles.amount_wrapper}>
                <div className={styles.amount_title}>{t('amount')}</div>
                <div className={styles.amount}>${cashback?.available?.toFixed(2)}</div>
            </div>
        </>
    );
};

export default AmountSection;
