import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { DatePicker as MantineDatePicker } from '@mantine/dates';
import '@mantine/dates/styles.css';
import Input from '@components/common/input';
import moment from 'moment';
import { formatDateForMoment, isValidDateFormat } from '@utils/date';
import './styles.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { getBirthDisabledDates, getDisableFeature, getDisablePast } from '@utils/index';

interface IProps {
    helperText?: string;
    label?: string;
    placeholder?: string;
    full?: boolean;
    error?: boolean;
    readOnly?: boolean;
    value?: string | null;
    maxDate?: Date;
    minDate?: Date;
    onChange?: (val: string | null) => void;
    disabled?: boolean;
    isChange?: boolean;
    isBirthday?: boolean;
    isDisablePast?: boolean;
    isDisableFeature?: boolean;
}

const DatePicker: FC<IProps> = ({
    isDisableFeature,
    isDisablePast,
    isBirthday,
    disabled,
    isChange = true,
    helperText,
    label,
    placeholder,
    full,
    error,
    readOnly,
    value,
    maxDate,
    minDate,
    onChange
}) => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [textValue, setTextValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isShown, setIsShown] = useState(true);
    const ref = useRef(null);

    useClickOutside({
        ref,
        callback: () => setIsOpen(false)
    });

    useEffect(() => {
        if (value) {
            setTextValue(moment(formatDateForMoment(value)).format('MM/DD/YYYY'));
        }
    }, [value]);

    useEffect(() => {
        setIsShown(false);
        setTimeout(() => setIsShown(true), 0);
    }, [value]);

    const onChangeInputValue = (val: string) => {
        if (value) {
            onChange && onChange(null);
        } else {
            if (isValidDateFormat(val)) {
                const parsedDate = moment(formatDateForMoment(val, true));

                const isWithinRange =
                    (!calculateMinDate || parsedDate.isSameOrAfter(moment(calculateMinDate))) &&
                    (!calculateMaxDate || parsedDate.isSameOrBefore(moment(calculateMaxDate)));

                if (isWithinRange) {
                    onChange && onChange(parsedDate.toISOString());
                } else {
                    console.error('Date is out of range');
                }
            }
        }
        setTextValue(val);
    };

    const calculateMinDate = useMemo(() => {
        if (minDate) return minDate;
        if (isDisablePast) return getDisablePast();
        return undefined;
    }, [minDate, isDisablePast]);

    const calculateMaxDate = useMemo(() => {
        if (maxDate) return minDate;
        if (isDisableFeature) return getDisableFeature();
        if (isBirthday) return getBirthDisabledDates();
        return undefined;
    }, [maxDate, isDisableFeature, isBirthday]);

    const calculateDefaultValue = useMemo(() => {
        return calculateMaxDate || calculateMinDate;
    }, [calculateMaxDate, calculateMinDate]);

    useEffect(() => {
        if (isFirstRender && value && isChange) {
            onChange && onChange(calculateDefaultValue?.toISOString() || null);
        }
        setIsFirstRender(false);
    }, [calculateDefaultValue]);

    return (
        <div className={`wrapper ${full ? 'full' : ''}`} ref={ref}>
            <Input
                value={textValue}
                onChange={onChangeInputValue}
                rightIcon="calendar"
                full={full}
                readOnly={readOnly}
                helperText={helperText}
                error={error}
                disabled={disabled}
                label={label}
                placeholder={placeholder || 'MM/DD/YYYY'}
                onClickIcon={() => setIsOpen((state) => !state)}
                onFocus={() => setIsOpen(true)}
            />
            {isOpen && (
                <div className="datepicker_wrapper">
                    {isShown && (
                        <MantineDatePicker
                            value={value ? new Date(value) : null}
                            onChange={(val) =>
                                onChange && onChange((val && val.toISOString()) || null)
                            }
                            className="dropdown"
                            defaultDate={value ? new Date(value) : undefined}
                            maxDate={calculateMaxDate}
                            minDate={calculateMinDate}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default DatePicker;
