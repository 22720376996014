import React, { FC, useEffect, useMemo, useState } from 'react';
import useCollapse from '@hooks/helpers/useCollapse';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import Select from '@components/common/select';
import { EDdrStatus, TContact } from '@xeppt/xeppt-sdk/types';
import { apiAccountService, apiContactService } from '@api';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useUserContext } from '@hooks/context/useUserContext';
import Skeleton from '@components/common/skeleton';
import SearchSelect from '@components/common/search_select';
import { useDebounceValue } from 'usehooks-ts';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useInteracModals } from '@hooks/modals/useInteracModals';
import {
    convertContactsListToOptions,
    excludeExistedContacts,
    getDisablePast,
    searchByContacts
} from '@utils/index';
import Textarea from '@components/common/textarea';
import { getFrequencyList } from '@utils/billing';
import DatePicker from '@components/date_picker';
import Radio from '@components/common/radio';
import { EScheduleFrequency } from '@xeppt/xeppt-sdk/types/schedule';
import { useFrequency } from '@hooks/helpers/useFrequency';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import Checkbox from '@components/common/checkbox';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    refetchContacts: () => void;
    contactList: TContact[];
    method: ESendRequestMoneyMethod;
}

const Info: FC<IProps> = ({ onNextStep, onPrevStep, method, contactList, refetchContacts }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.request_money.info'
    });
    const { t: recurringLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.recurring'
    });
    const [scheduleId] = useQueryParams(['id']);
    const { labelLocale, submitLocale, validationLocale, requestErrorLocale } = useLocales();
    const { isOpened, onToggle, contentStyles, contentRef } = useCollapse(true);
    const { user, account } = useUserContext();
    const navigate = useNavigate();
    const form = useFormContext();
    const { handleChangeInteracEmail } = useInteracModals();
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);
    const [search, setSearch] = useState('');
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control: form.control });
    const watchedNextPaymentAt = useWatch({ name: 'nextPaymentAt', control: form.control });
    const isInfinitePayment = useWatch({ name: 'isInfinitePayment', control: form.control });
    const isBankRequest = useMemo(() => method === ESendRequestMoneyMethod.BANK_REQUEST, [method]);
    const isWalletRequest = useMemo(
        () => method === ESendRequestMoneyMethod.INTERNAL_REQUEST,
        [method]
    );
    const isFrequencyExist = useMemo(
        () => watchedFrequency !== EScheduleFrequency.ONCE,
        [watchedFrequency]
    );
    useFrequency({ isFrequencyExist, method });
    const isETransferRequest = useMemo(
        () => method === ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
        [method]
    );

    const { data: searchContacts } = useApiQuery({
        method: () => apiContactService.searchContact(requestSearch),
        deps: [requestSearch],
        condition: requestSearch.length >= 3
    });

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    const { data: ddrStatus, isLoading: isDdrLoading } = useApiQuery({
        method: () => apiAccountService.checkETransferDdr(),
        condition: isETransferRequest
    });

    const { handleRequest: handleSaveContact } = useApiMutation({
        method: () => {
            const values = form.getValues();
            return apiContactService.addContact(values.contact);
        },
        onSuccess: (data) => {
            form.setValue('contact', data.id);
            refetchContacts();
            onNextStep();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        const values = form.getValues();
        form.trigger().then((isValid) => {
            const foundUserSearch = searchContacts?.find((item) => item.id === values.contact);
            const foundUserContact =
                contactList.find((item) => item.tag === foundUserSearch?.tag) ||
                contactList.find((item) => item.id === values?.contact);
            if (isValid) {
                if (isWalletRequest && !foundUserContact && !scheduleId) {
                    handleSaveContact(undefined);
                } else {
                    form.setValue('contact', foundUserContact?.id);
                    onNextStep();
                }
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {t(isETransferRequest ? 'description' : 'description_internal')}
                </Typography>
            </div>
            <div className={`${styles.content} ${styles.frequency}`}>
                <FormField
                    name="amount"
                    renderComponent={(props) => (
                        <Input
                            full
                            label={labelLocale('amount')}
                            prefix="CAD"
                            rightIcon="canadian"
                            fixedAmount
                            hideErrorIcon
                            onlyNumbers
                            {...props}
                        />
                    )}
                    rules={{ required: { value: true, message: validationLocale('amount') } }}
                />
                {method !== ESendRequestMoneyMethod.E_TRANSFER_REQUEST && (
                    <>
                        <div className={styles.form_row}>
                            <FormField<string | number>
                                name="paymentFrequency"
                                renderComponent={(props) => (
                                    <Select
                                        full
                                        label={labelLocale('frequency')}
                                        {...props}
                                        items={getFrequencyList(recurringLocale)}
                                        className={styles.frequency}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validationLocale('frequency')
                                    }
                                }}
                            />
                            <FormField<string | null>
                                name="nextPaymentAt"
                                renderComponent={(props) => (
                                    <DatePicker
                                        label={recurringLocale('next_payment')}
                                        minDate={getDisablePast()}
                                        full
                                        {...props}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validationLocale('next_payment')
                                    }
                                }}
                            />
                        </div>
                        <div className={styles.form_row}>
                            {watchedFrequency !== EScheduleFrequency.ONCE && (
                                <FormField<boolean>
                                    name="isInfinitePayment"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.row}>
                                            <Checkbox
                                                checked={value}
                                                onClick={() => {
                                                    onChange(!value);
                                                }}
                                            />
                                            {t('infinite_payment')}
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                        {isFrequencyExist && !isInfinitePayment && (
                            <div className={styles.form_row}>
                                <div className={styles.full_row}>
                                    <FormField<ESchedulePaymentType>
                                        name="paymentType"
                                        renderComponent={({ value, onChange }) => (
                                            <>
                                                <div
                                                    className={styles.row}
                                                    onClick={() =>
                                                        onChange(ESchedulePaymentType.PAYMENTS_DATE)
                                                    }>
                                                    <Radio
                                                        small
                                                        checked={
                                                            value ===
                                                            ESchedulePaymentType.PAYMENTS_DATE
                                                        }
                                                    />{' '}
                                                    <Typography variant="body3">
                                                        {recurringLocale('ending_date')}
                                                    </Typography>
                                                </div>
                                                <FormField<string | null>
                                                    name="paymentsEndDate"
                                                    renderComponent={(props) => (
                                                        <DatePicker
                                                            full
                                                            label={recurringLocale('ending_date')}
                                                            disabled={
                                                                value !==
                                                                ESchedulePaymentType.PAYMENTS_DATE
                                                            }
                                                            minDate={new Date(watchedNextPaymentAt)}
                                                            {...props}
                                                        />
                                                    )}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className={styles.full_row}>
                                    <FormField<ESchedulePaymentType>
                                        name="paymentType"
                                        renderComponent={({ value, onChange }) => (
                                            <>
                                                <div
                                                    className={styles.row}
                                                    onClick={() =>
                                                        onChange(
                                                            ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                        )
                                                    }>
                                                    <Radio
                                                        small
                                                        checked={
                                                            value ===
                                                            ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                        }
                                                    />{' '}
                                                    <Typography variant="body3">
                                                        <span>{recurringLocale('or')}</span>{' '}
                                                        {recurringLocale('end')}
                                                    </Typography>
                                                </div>
                                                <FormField
                                                    name="numberOfPayments"
                                                    renderComponent={(props) => (
                                                        <Input
                                                            disabled={
                                                                value !==
                                                                ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                            }
                                                            full
                                                            label={labelLocale(
                                                                'number_of_payments'
                                                            )}
                                                            onlyNumbers
                                                            {...props}
                                                        />
                                                    )}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {method !== ESendRequestMoneyMethod.BANK_REQUEST &&
                    watchedFrequency === EScheduleFrequency.ONCE && (
                        <FormField
                            name="message"
                            renderComponent={(props) => (
                                <Textarea
                                    full
                                    label={labelLocale('message_optional')}
                                    maxLength={250}
                                    {...props}
                                />
                            )}
                        />
                    )}
            </div>
            {!scheduleId && (
                <>
                    {method !== ESendRequestMoneyMethod.BANK_REQUEST && (
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <Typography variant="h4">{t('from')}</Typography>
                                <div className={styles.actions}>
                                    <Button
                                        variant="outlined-dark"
                                        size="small"
                                        onClick={() => navigate(routes.send_and_request_contacts)}>
                                        {submitLocale('add_new')}
                                    </Button>
                                </div>
                            </div>
                            {isETransferRequest && (
                                <FormField<string | number>
                                    name="contact"
                                    renderComponent={(props) => (
                                        <Select
                                            full
                                            label={labelLocale('contact')}
                                            items={
                                                contactList?.map((item) => ({
                                                    //@ts-ignore
                                                    label: `${item.firstName} ${item.lastName} (${item.email || (item?.phone && `+${item?.phone}`)})`,
                                                    value: item.id
                                                })) || []
                                            }
                                            {...props}
                                        />
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validationLocale('contact')
                                        }
                                    }}
                                />
                            )}
                            {isWalletRequest && (
                                <FormField<string | number>
                                    name="contact"
                                    renderComponent={(props) => (
                                        <SearchSelect
                                            full
                                            label={labelLocale('contact')}
                                            splitArrays={[
                                                {
                                                    label: 'Your Contacts',
                                                    array: convertContactsListToOptions(
                                                        searchByContacts(search, contactList)
                                                    )
                                                },
                                                {
                                                    label: 'XEPPT Contacts',
                                                    array: convertContactsListToOptions(
                                                        excludeExistedContacts(
                                                            contactList,
                                                            searchContacts
                                                        )
                                                    )
                                                }
                                            ]}
                                            search={search}
                                            onChangeSearch={(val) => setSearch(val)}
                                            {...props}
                                        />
                                    )}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: validationLocale('contact')
                                        }
                                    }}
                                />
                            )}
                            {isETransferRequest && (
                                <>
                                    <div className={styles.content}>
                                        <div className={styles.header}>
                                            <Typography variant="h4">{t('by')}</Typography>
                                            <div className={styles.actions}>
                                                <Typography variant="body3">
                                                    {user?.profile?.firstName}{' '}
                                                    {user?.profile?.lastName} (
                                                    {account?.eTransferAccount?.interacEmail})
                                                </Typography>
                                                <Button
                                                    leftIcon="edit"
                                                    onClick={handleChangeInteracEmail}
                                                />
                                            </div>
                                        </div>
                                        {isDdrLoading ? (
                                            <Skeleton className={styles.skeleton} />
                                        ) : (
                                            ddrStatus !== EDdrStatus.ENABLED && (
                                                <Typography
                                                    variant="body3"
                                                    className={styles.balance}>
                                                    {t('autodeposit')}
                                                </Typography>
                                            )
                                        )}
                                    </div>
                                    <div className={styles.submit_wrapper}>
                                        {ddrStatus !== EDdrStatus.ENABLED && (
                                            <Button
                                                variant="primary"
                                                size="normal"
                                                onClick={() =>
                                                    navigate(routes.send_and_request_autodeposit)
                                                }>
                                                {submitLocale('go_to_autodeposit')}
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {isBankRequest && (
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <Typography variant="h4">{t('from')}</Typography>
                                <div className={styles.actions}>
                                    <Button
                                        variant="outlined-dark"
                                        size="small"
                                        onClick={() => navigate(routes.link_card_bank)}>
                                        {submitLocale('add_new')}
                                    </Button>
                                </div>
                            </div>
                            <FormField<string | number>
                                name="account"
                                renderComponent={(props) => (
                                    <Select
                                        full
                                        label={labelLocale('bank_account')}
                                        items={
                                            account?.bankAccounts?.map((item) => ({
                                                label: `${item.institutionName} (${item.accountName} ${item.accountNumber})`,
                                                value: item.id
                                            })) || []
                                        }
                                        {...props}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validationLocale('bank_account')
                                    }
                                }}
                            />
                        </div>
                    )}
                </>
            )}
            <div className={styles.actions}>
                <Button
                    size="normal"
                    leftIcon="arrow_left"
                    variant="outlined-dark"
                    onClick={onPrevStep}>
                    {submitLocale('back')}
                </Button>
                <Button variant="primary" size="normal" onClick={onSubmit}>
                    {submitLocale('next')}
                </Button>
            </div>
            <div className={styles.fees}>
                <Button onClick={onToggle}>
                    <div className={`${styles.icon} ${isOpened ? styles.isOpen : ''}`} />{' '}
                    <Typography variant="h5">{t('fees')}</Typography>
                </Button>
                <div
                    className={`${styles.fees_container} ${isOpened ? styles.isOpen : ''}`}
                    style={contentStyles}
                    ref={contentRef}>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('fee_personal')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('fee_personal_1')}</Typography>
                            <Typography>{t('fee_personal_2')}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('fee_personal_3')}</Typography>
                            <Typography>$5 {t('fee_personal_4')}</Typography>
                        </div>
                    </div>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('fee_business')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('fee_business_1')}</Typography>
                            <Typography>$1.50 {t('fee_business_2')}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('fee_business_3')}</Typography>
                            <Typography>$5 {t('fee_business_4')}</Typography>
                        </div>
                    </div>
                </div>
                <div className={styles.notes}>
                    {t('notes')}
                    <div className={styles.row}>
                        <span>1.</span>
                        <div>{t('notes_1')}</div>
                    </div>
                    <div className={styles.row}>
                        <span>2.</span>
                        <div>{t('notes_2')}</div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Info;
