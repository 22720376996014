import { useProgressBar } from '@hooks/helpers/useProgressBar';
import Info from '@sections/send_and_request/send_money/info';
import Success from '@sections/send_and_request/send_money/success';
import Verification from '@sections/send_and_request/send_money/verification';
import styles from './styles.module.scss';
import ProgressBar, { TProgressItem } from '@components/progress_bar';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider, useWatch } from 'react-hook-form';
import { apiAccountService, apiContactService, apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferSendMoneyProgressSteps } from '@const/progress_bar_steps';
import { useEffect, useState } from 'react';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { ETransactionAction, ETransactionMethod, TContact } from '@xeppt/xeppt-sdk/types';
import ChooseMethodSection from '@sections/send_and_request/choose_methods/method';
import { useTranslation } from 'react-i18next';
import { EContactType } from '@xeppt/xeppt-sdk/types/wallet';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import { EScheduleFrequency } from '@xeppt/xeppt-sdk/types/schedule';
import { useNavigate } from 'react-router-dom';

const defaultPaymentData = {
    paymentType: ESchedulePaymentType.PAYMENTS_DATE,
    contact: '',
    amount: undefined,
    securityQuestion: '',
    securityAnswer: '',
    account: '',
    message: '',
    paymentFrequency: EScheduleFrequency.ONCE,
    nextPaymentAt: new Date(),
    numberOfPayments: 1,
    isInfinitePayment: false
};

const SendMoneySection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(
        eTransferSendMoneyProgressSteps
    );
    const [type, contactId, scheduleId] = useQueryParams(['type', 'contactId', 'id']);
    const formMethods = useForm({
        defaultValues: defaultPaymentData
    });
    const { refetchAccount } = useUserContext();
    const [sendMethod, setSendMethod] = useState<ESendRequestMoneyMethod>(
        ESendRequestMoneyMethod.INTERNAL
    );
    const navigate = useNavigate();
    const [contactsList, setContactsList] = useState<TContact[]>([]);
    const [isMethodChosen, setIsMethodChosen] = useState(false);
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control: formMethods.control });

    useEffect(() => {
        if (type) {
            //@ts-ignore
            setSendMethod(type as ESendRequestMoneyMethod);
            setIsMethodChosen(true);
        }
        if (contactId) {
            formMethods.setValue('contact', contactId);
        }
    }, [type, contactId, contactsList]);

    const { handleRequest: handleSendMoneyByETransfer, isLoading: isEtransferRequestLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                return apiAccountService.sendMoneyETransfer({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    securityQuestion: values.securityQuestion,
                    securityAnswer: values.securityAnswer,
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('send_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const getTransactionMethod = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.INTERNAL:
                return ETransactionMethod.WALLET;
            case ESendRequestMoneyMethod.BANK:
                return ETransactionMethod.EFT;
        }
    };

    useApiQuery({
        method: () => apiScheduleService.getSchedule(scheduleId as string),
        condition: !!scheduleId,
        onSuccess: (data) => {
            formMethods.reset({
                paymentType: ESchedulePaymentType.PAYMENTS_DATE,
                contact: data.method === ETransactionMethod.WALLET ? data.referenceId : undefined,
                //@ts-ignore
                amount: data.amount,
                account: data.method === ETransactionMethod.EFT ? data.referenceId : undefined,
                paymentFrequency: data.frequency,
                nextPaymentAt: data.nextPaymentAt,
                numberOfPayments: data.remainingPayments,
                isInfinitePayment: data.remainingPayments === -1
            });
        },
        deps: [scheduleId]
    });

    const { handleRequest: createSchedulePayment, isLoading: isScheduleLoading } = useApiMutation({
        method: () => {
            const values = formMethods.getValues();
            const {
                paymentFrequency,
                amount,
                numberOfPayments,
                nextPaymentAt,
                isInfinitePayment,
                contact,
                account
            } = values;
            return apiScheduleService.createSchedule({
                amount: Number(amount || 0),
                nextPaymentAt,
                frequency: paymentFrequency,
                numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments),
                referenceId: sendMethod === ESendRequestMoneyMethod.BANK ? account : contact,
                method: getTransactionMethod() as ETransactionMethod,
                action: ETransactionAction.SEND_MONEY
            });
        },
        onSuccess: () => {
            requestSuccessLocale('create_schedule_payment');
            nextStep();
            refetchAccount();
            formMethods.reset(defaultPaymentData);
        },
        onError: requestErrorLocale
    });

    const { handleRequest: updateSchedulePayment, isLoading: isUpdateScheduleLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                const {
                    paymentFrequency,
                    amount,
                    numberOfPayments,
                    nextPaymentAt,
                    isInfinitePayment
                } = values;
                return apiScheduleService.updateSchedule(scheduleId as string, {
                    amount: Number(amount || 0),
                    nextPaymentAt,
                    frequency: paymentFrequency,
                    numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments)
                });
            },
            onSuccess: () => {
                requestSuccessLocale('update_schedule_payment');
                nextStep();
                refetchAccount();
                formMethods.reset(defaultPaymentData);
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleSendMoneyByWallet, isLoading: isWalletRequestLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                return apiAccountService.sendMoneyWallet({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('send_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleSendMoneyByEft, isLoading: isEftRequestLoading } = useApiMutation({
        method: () => {
            const values = formMethods.getValues();
            return apiAccountService.sendMoneyEft({
                id: values.account,
                amount: Number(values.amount)
            });
        },
        onSuccess: () => {
            requestSuccessLocale('send_money');
            nextStep();
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        if (scheduleId) {
            updateSchedulePayment(undefined);
        } else {
            if (watchedFrequency !== EScheduleFrequency.ONCE) {
                createSchedulePayment(undefined);
            } else {
                switch (sendMethod) {
                    case ESendRequestMoneyMethod.E_TRANSFER:
                        handleSendMoneyByETransfer(undefined);
                        break;
                    case ESendRequestMoneyMethod.INTERNAL:
                        handleSendMoneyByWallet(undefined);
                        break;
                    case ESendRequestMoneyMethod.BANK:
                        handleSendMoneyByEft(undefined);
                        break;
                }
            }
        }
    };

    const { data: contacts, handleRequest: refetchContacts } = useApiQuery({
        method: () => apiContactService.getContacts()
    });

    useEffect(() => {
        if (contacts) {
            if (sendMethod === ESendRequestMoneyMethod.E_TRANSFER) {
                setContactsList(contacts.filter((item) => item.type === EContactType.INTERAC));
            } else {
                setContactsList(contacts.filter((item) => item.type === EContactType.XEPPT));
            }
        }
    }, [sendMethod, contacts]);

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <Info
                        method={sendMethod}
                        onNextStep={nextStep}
                        refetchContacts={refetchContacts}
                        onPrevStep={() => {
                            setIsMethodChosen(false);
                            if (scheduleId) {
                                navigate(window.location.pathname);
                            }
                        }}
                        contactsList={contactsList || []}
                    />
                );
            case '2':
                return (
                    <Verification
                        contactList={contactsList}
                        isLoading={
                            isWalletRequestLoading ||
                            isEftRequestLoading ||
                            isEtransferRequestLoading ||
                            isUpdateScheduleLoading ||
                            isScheduleLoading
                        }
                        onSubmit={onSubmit}
                        onPrevStep={prevStep}
                        method={sendMethod}
                    />
                );
            default:
                return (
                    <Success
                        onRepeat={() => {
                            changeStep('1');
                            if (scheduleId) {
                                navigate(window.location.pathname);
                            }
                        }}
                    />
                );
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            {isMethodChosen ? (
                <>
                    <div className={styles.progress_wrapper}>
                        <div className={styles.content}>
                            <ProgressBar
                                variant="light"
                                size="large"
                                steps={
                                    eTransferSendMoneyProgressSteps.filter(
                                        (item) => item !== undefined
                                    ) as TProgressItem[]
                                }
                                availableStep={availableStep}
                                currentStep={currentStep}
                                className={styles.progress}
                            />
                        </div>
                    </div>
                    <FormProvider {...formMethods}>{renderContent()}</FormProvider>
                </>
            ) : (
                <ChooseMethodSection
                    method={sendMethod}
                    onChangeMethod={(val) => setSendMethod(val)}
                    onSubmit={() => setIsMethodChosen(true)}
                    title={t('title')}
                    type="send"
                    styles={styles}
                />
            )}
        </motion.div>
    );
};

export default SendMoneySection;
