import React, { FC } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { getBillingStatusList, getFrequencyList } from '@utils/billing';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { formatDateForMoment } from '@utils/date';
import Info from '@components/common/info';
import { EScheduleFrequency, EScheduleStatus, TSchedule } from '@xeppt/xeppt-sdk/types/schedule';
import Input from '@components/common/input';
import { getTransactionAction } from '@utils/index';
import { enumTranslate } from '@locales/index';
import { ETransactionAction, ETransactionMethod } from '@xeppt/xeppt-sdk/types/transaction';

interface IProps {
    onEdit: (data: TSchedule) => void;
    onSearch: (value: string) => void;
    refetchPaymentsList: () => void;
    search: string;
    paymentsList: TSchedule[];
    filter: { paymentFrequency?: EScheduleFrequency[]; status?: EScheduleStatus[] };
    onFilterChange: (val: {
        paymentFrequency?: EScheduleFrequency[];
        status?: EScheduleStatus[];
    }) => void;
}

const PaymentsTable: FC<IProps> = ({
    paymentsList,
    refetchPaymentsList,
    onEdit,
    onSearch,
    filter,
    search,
    onFilterChange
}) => {
    const { t, i18n } = useTranslation('sections', {
        keyPrefix: 'send_and_request.schedule_payments.table'
    });
    const { t: transactionLocale } = useTranslation('components', {
        keyPrefix: 'transaction_tables.transaction_item'
    });
    const { t: frequencyLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.schedule_payments.payment_details'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const { handleRequest: handleCancelPayment, isLoading: isCancelPaymentLoading } =
        useApiMutation({
            method: (id: string) => {
                return apiScheduleService.deleteSchedule(id);
            },
            onSuccess: () => {
                requestSuccessLocale('cancel_schedule');
                refetchPaymentsList();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleDeactivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.deactivateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('pause_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleActivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.activateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('activate_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const handlePauseResumePayment = (id: string, status: EScheduleStatus) => {
        if (status === EScheduleStatus.ACTIVE) {
            handleDeactivatePayment(id);
        } else {
            handleActivatePayment(id);
        }
    };

    const columns = [
        {
            label: t('name'),
            key: 'info',
            width: 300
        },
        {
            label: t('frequency'),
            key: 'frequency',
            render: (value: EScheduleFrequency) =>
                getFrequencyList(frequencyLocale).find((item) => item.value === value)?.label,
            filterOptions: getFrequencyList(frequencyLocale)
        },
        {
            label: t('method'),
            key: 'method',
            render: (_: string, data: TSchedule) =>
                getTransactionAction(data.action, data.method, transactionLocale),
            filterOptions: [
                { label: transactionLocale('wallet'), value: ETransactionMethod.WALLET },
                { label: transactionLocale('bank'), value: ETransactionMethod.WALLET }
            ]
        },
        {
            label: t('action'),
            key: 'action',
            render: (_: string, data: TSchedule) =>
                enumTranslate[
                    data.action === ETransactionAction.SEND_MONEY &&
                    data.method === ETransactionMethod.EFT
                        ? 'to_bank'
                        : data.method
                ][i18n.language as 'en' | 'fr'],
            filterOptions: [
                { label: transactionLocale('send_money'), value: ETransactionAction.SEND_MONEY },
                {
                    label: transactionLocale('request_money'),
                    value: ETransactionAction.REQUEST_MONEY
                }
            ]
        },
        {
            label: t('status'),
            key: 'status',
            render: (value: EScheduleStatus) =>
                getBillingStatusList(frequencyLocale).find((item) => item.value === value)?.label,
            filterOptions: getBillingStatusList(frequencyLocale)
        },
        {
            label: t('amount'),
            key: 'amount',
            render: (value: number) => `$${value.toFixed(2)}`
        },
        {
            label: 'Payments',
            key: 'remainingPayments',
            render: (value: number) => (value === -1 ? 'Infinite' : value)
        },
        {
            label: t('payment_date'),
            key: 'nextPaymentAt',
            render: (value: string) =>
                value ? moment(formatDateForMoment(value)).format('MM/DD/YYYY') : '-'
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: undefined, data: TSchedule) => (
                <div className={styles.table_actions} key={data.id}>
                    {(data.status === EScheduleStatus.ACTIVE ||
                        data.status === EScheduleStatus.INACTIVE) && (
                        <Info
                            maxWidth
                            variant="left"
                            content={data.status === EScheduleStatus.ACTIVE ? 'Pause' : 'Resume'}>
                            <Button
                                leftIcon={data.status === EScheduleStatus.ACTIVE ? 'pause' : 'play'}
                                onClick={() => handlePauseResumePayment(data.id, data.status)}
                            />
                        </Info>
                    )}
                    {data.status !== EScheduleStatus.COMPLETE && (
                        <Info maxWidth variant="left" content="Edit">
                            <Button leftIcon="edit" onClick={() => onEdit(data)} />
                        </Info>
                    )}
                    <Info maxWidth variant="left" content="Delete">
                        <Button
                            leftIcon="trash"
                            className={styles.delete}
                            isLoading={isCancelPaymentLoading}
                            onClick={() => handleCancelPayment(data.id)}
                        />
                    </Info>
                </div>
            )
        }
    ];

    return (
        <motion.div {...pageAnimation} className={styles.content}>
            <div className={styles.content}>
                <div className={styles.form_row}>
                    <Input
                        value={search}
                        onChange={(val) => onSearch(val)}
                        full
                        label={t('search_schedule')}
                        rightIcon="search"
                    />
                </div>
            </div>
            <Table
                //@ts-ignore
                columns={columns}
                rows={paymentsList}
                className={styles.table}
                emptyDescription={t('empty_payments')}
                isActions
                //@ts-ignore
                filter={filter}
                onFilterChange={(val) => {
                    onFilterChange({
                        ...filter,
                        ...val
                    });
                }}
            />
        </motion.div>
    );
};

export default PaymentsTable;
