import React, { FC, useMemo, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { Country } from 'country-state-city';
import useClickOutside from '@hooks/helpers/useClickOutside';
import FlagIcon from '@components/common/flag_icon';

interface IProps {
    value?: string;
    onChange?: (value: string) => void;
    label?: string;
    placeholder?: string;
    readOnly?: boolean;
    full?: boolean;
    disabled?: boolean;
    selectSize?: 'small' | 'large';
}

const items = Country.getAllCountries().map((country) => ({
    isoCode: country.isoCode,
    label: `${country.phonecode}`,
    value: country.phonecode
}));

const cx = classNames.bind(styles);

const Select: FC<IProps> = ({
    value = '',
    placeholder,
    selectSize = 'large',
    readOnly,
    disabled,
    full,
    label,
    onChange
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState<string>();
    const selectRef = useRef(null);

    const handleChange = (value: string) => {
        if (onChange) onChange(value);
        setSearch(undefined);
        setIsOpen(false);
    };

    useClickOutside({
        ref: selectRef,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    const foundValue = useMemo(() => {
        return items.find((item) => item.value === value);
    }, [value, items, search]);

    return (
        <div className={cx([styles.wrapper, { isFull: full }])} ref={selectRef}>
            <Input
                rightIcon="nav_down"
                value={value && search === undefined ? foundValue?.label : search}
                placeholder={placeholder}
                label={label}
                readOnly={readOnly}
                full={full}
                className={cx([{ isOpen }])}
                inputClassName={styles.input}
                onFocus={() => {
                    if (!readOnly || disabled) setIsOpen(true);
                }}
                onClickIcon={() => {
                    if (!readOnly || disabled) setIsOpen((state) => !state);
                }}
                disabled={disabled}
                onChange={(val) => {
                    if (value && search === undefined) {
                        setSearch('');
                        if (onChange) onChange('');
                    } else {
                        setSearch(val);
                    }
                }}
            />
            {foundValue && (
                <div className={styles.chosen_flag}>
                    <FlagIcon name={foundValue.isoCode} width={22} height={22} />
                </div>
            )}
            <div className={cx([styles.dropdown, { isOpen, isLarge: selectSize === 'large' }])}>
                {items
                    .filter((item) =>
                        item.label.toLowerCase().includes((search || '').toLowerCase())
                    )
                    .map((item, i) => (
                        <button
                            className={styles.select_button}
                            key={i}
                            onClick={() => {
                                if (!disabled) handleChange(item.value);
                            }}>
                            <FlagIcon name={item.isoCode} width={22} height={22} /> {item.label}
                        </button>
                    ))}
            </div>
        </div>
    );
};

export default Select;
