import React, { FC, useEffect, useState } from 'react';
import Table from '@components/table';
import { TCashbackFilter } from '@xeppt/xeppt-sdk/types';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import styles from '@layouts/cashback/styles.module.scss';
import moment from 'moment-timezone';
import CashbackStatus from '@components/common/cashback_status';
import { capitalizeFirstLetter } from '@utils/index';

interface IProps {
    search?: string;
    dateFrom?: Date;
    dateTo?: Date;
}

const CashbackTable: FC<IProps> = ({ search, dateTo, dateFrom }) => {
    const [cursor, setCursor] = useState<Date>();
    const [filter, setFilter] = useState<TCashbackFilter>({
        order: 'DESC',
        status: undefined
    });

    useEffect(() => {
        setFilter((state) => ({
            ...state,
            dateTo,
            dateFrom
        }));
        if (dateFrom) {
            setCursor(dateFrom);
        }
    }, [dateTo, dateFrom]);

    const columns = [
        {
            label: 'Date & Time',
            key: 'createdAt',
            isSort: true,
            render: (val: Date) => (
                <div className={styles.date_wrapper}>
                    <span>{val && moment(val).format('MMM DD, YYYY')}</span>
                    <span>{val && moment(val).format('HH:mm')}</span>
                </div>
            )
        },
        {
            label: 'Store Name',
            key: 'merchant'
        },
        {
            label: 'Order ID',
            key: 'orderId'
        },
        {
            label: 'Status',
            key: 'status',
            render: (val: any) => (
                <CashbackStatus variant={val}>
                    {capitalizeFirstLetter(val.toLowerCase())}
                </CashbackStatus>
            ),
            filterOptions: [
                { label: 'Pending', value: 'PENDING' },
                { label: 'Confirmed', value: 'CONFIRMED' },
                { label: 'Paid', value: 'PAID' },
                { label: 'Ready', value: 'READY' },
                { label: 'Withdrawn', value: 'REDEEMED' }
            ]
        },
        {
            label: 'Purchase Amount',
            key: 'saleAmount',
            render: (val: number) => `$${val?.toFixed(2)}`
        },
        {
            label: 'Cashback Amount',
            key: 'deviceAmount',
            render: (val: number) => `$${val?.toFixed(2)}`
        }
    ];

    const { data: cashbackTransactions, handleRequest: refetchCashbackTransactions } = useApiQuery({
        method: (data: any) => {
            return apiAccountService.getCashbackList(
                data?.isNew
                    ? {}
                    : {
                          ...data?.filter,
                          status:
                              (data?.filter?.status?.length || 0) > 0
                                  ? data?.filter?.status
                                  : undefined,
                          dateTo,
                          dateFrom
                      },
                {
                    cursor: cursor,
                    limit: 10
                }
            );
        },
        onSuccess: (data, request: any) => {
            if (cursor !== data.cursor && !request?.isNew) {
                setCursor(data.cursor);
            }
        },
        deps: [dateTo, dateFrom, search]
    });

    return (
        <Table
            columns={columns}
            rows={cashbackTransactions?.data || []}
            emptyDescription="No transactions yet"
            totalRows={cashbackTransactions?.total}
            onLoadData={() => {
                refetchCashbackTransactions({ filter });
            }}
            //@ts-ignore
            filter={{ status: filter.status }}
            //@ts-ignore
            sort={{ key: 'createdAt', value: filter.order }}
            onSortChange={(_, value) => {
                setFilter((state) => ({
                    ...state,
                    order: value.toUpperCase() as 'ASC' | 'DESC'
                }));
                setCursor(undefined);
                refetchCashbackTransactions({
                    isNew: false,
                    filter: {
                        ...filter,
                        order: value.toUpperCase() as 'ASC' | 'DESC'
                    }
                });
            }}
            onFilterChange={(val) => {
                setFilter((state) => ({
                    ...state,
                    ...val
                }));
                setCursor(undefined);
                refetchCashbackTransactions({
                    isNew: false,
                    filter: {
                        ...filter,
                        ...val
                    }
                });
            }}
        />
    );
};

export default CashbackTable;
