import React, { FC, useCallback, useState } from 'react';
import styles from '@sections/send_and_request/manage_contacts/styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import { apiContactService } from '@api';
import { EContactType, TContact } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Typography from '@components/common/typography';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useSendAndRequestModals } from '@hooks/modals/useSendAndRequestModals';
import { getFirstLetter } from '@utils/index';
import classNames from 'classnames/bind';
import Tabs from '@components/tabs';
import { EContactViewType } from '@enum';
import Input from '@components/common/input';
import Info from '@components/common/info';

interface IProps {
    onAdd: () => void;
    refetchContacts: () => void;
    handleEdit: (data: TContact) => void;
    onChangeTab: (data: EContactViewType) => void;
    activeTab: EContactViewType;
    blockedContacts: TContact[];
    contactList: TContact[];
}

const cx = classNames.bind(styles);

const tabs = [EContactViewType.CONTACTS, EContactViewType.BLOCKED];

const ContactsTable: FC<IProps> = ({
    onAdd,
    contactList,
    refetchContacts,
    handleEdit,
    blockedContacts,
    activeTab,
    onChangeTab
}) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_contacts'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { handleDeleteContactModal, handleOpenContactPaymentModal, handleDeleteWalletContact } =
        useSendAndRequestModals();
    const [search, setSearch] = useState('');

    const { handleRequest: handleUnlockContact } = useApiMutation({
        method: (val: string) => {
            return apiContactService.unblockContact(val);
        },
        onSuccess: () => {
            refetchContacts();
            requestSuccessLocale('unblock_contact');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleBlockContact } = useApiMutation({
        method: (val: string) => {
            return apiContactService.blockContact(val);
        },
        onSuccess: () => {
            refetchContacts();
            requestSuccessLocale('block_contact');
        },
        onError: requestErrorLocale
    });

    const columns = [
        {
            label: t('name'),
            key: 'firstName',
            render: (_: string, data: TContact, index: number) => (
                <div className={styles.user_wrapper}>
                    <div className={cx([styles.user_avatar, { isDark: index % 2 }])}>
                        {getFirstLetter(data.firstName)}
                    </div>
                    {`${data.firstName} ${data.lastName}`}
                </div>
            )
        },
        {
            label: t('email_address'),
            key: 'email'
        },
        {
            label: t('phone'),
            key: 'phone',
            render: (val: string) => (val ? `+1 ${val}` : '-')
        },
        {
            label: t('type'),
            key: 'type',
            render: (value: EContactType) =>
                value === EContactType.INTERAC ? t('e_transfer') : t('xeppt')
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: string, data: TContact) => (
                <div className={styles.table_actions}>
                    {activeTab === EContactViewType.BLOCKED ? (
                        <Info maxWidth variant="left" content="Unlock">
                            <Button leftIcon="sync" onClick={() => handleUnlockContact(data.id)} />
                        </Info>
                    ) : (
                        <>
                            {data.type === EContactType.XEPPT ? (
                                <Info maxWidth variant="left" content="Block">
                                    <Button
                                        leftIcon="block"
                                        onClick={() => handleBlockContact(data.id)}
                                    />
                                </Info>
                            ) : (
                                <Info maxWidth variant="left" content="Edit">
                                    <Button leftIcon="edit" onClick={() => handleEdit(data)} />
                                </Info>
                            )}
                            <Info maxWidth variant="left" content="Delete">
                                <Button
                                    leftIcon="delete"
                                    className={styles.delete}
                                    onClick={() => {
                                        if (data.type === EContactType.INTERAC) {
                                            handleDeleteContactModal(data.id, refetchContacts);
                                        } else if (data.type === EContactType.XEPPT) {
                                            handleDeleteWalletContact(data.id, refetchContacts);
                                        }
                                    }}
                                />
                            </Info>
                        </>
                    )}
                </div>
            )
        }
    ];

    const contactsResults = useCallback(() => {
        const checkIsExist = (contact: TContact) => {
            return (
                contact?.tag?.toLowerCase().includes(search.toLowerCase()) ||
                contact?.email?.toLowerCase().includes(search.toLowerCase()) ||
                contact?.phone?.toLowerCase().includes(search.toLowerCase()) ||
                `${contact?.firstName?.toLowerCase()} ${contact?.lastName?.toLowerCase()}`?.includes(
                    search.toLowerCase()
                )
            );
        };
        return (
            (activeTab === EContactViewType.CONTACTS ? contactList : blockedContacts) || []
        ).filter(checkIsExist);
    }, [search, blockedContacts, contactList, activeTab]);

    return (
        <>
            <Button variant="primary" size="small" className={styles.new_contact} onClick={onAdd}>
                {t('add_contact_label')}
            </Button>
            <Tabs
                onChange={(val) => onChangeTab(val as EContactViewType)}
                tabs={tabs.map((item) => ({ label: t(item), value: item }))}
                activeTab={activeTab}
                className={styles.tabs}
            />
            <div className={styles.content}>
                <div className={styles.search_wrapper}>
                    <Typography variant="body3" className={styles.search_description}>
                        Enter contact’s name to search
                    </Typography>
                </div>
                <div className={styles.form_row}>
                    <Input
                        value={search}
                        onChange={(val) => setSearch(val)}
                        full
                        label={t('search_contact')}
                        rightIcon="search"
                    />
                </div>
            </div>
            <motion.div {...pageAnimation} className={styles.content}>
                <Table
                    columns={columns}
                    rows={contactsResults()}
                    onRowClick={(contact: TContact) => handleOpenContactPaymentModal(contact)}
                    isActions
                    emptyDescription="No contacts yet"
                />
            </motion.div>
        </>
    );
};

export default ContactsTable;
