import React, { FC } from 'react';
import styles from './styles.module.scss';
import Card from '@components/cards/card';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { capitalizeFirstLetter, hideCardNumber, prettifyCardType } from '@utils/index';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { ECardStatus, TCard } from '@xeppt/xeppt-sdk/types';
import Skeleton from '@components/common/skeleton';
import { useXepptCardModals } from '@hooks/modals/useXepptCardModals';
import { Icon } from '@components/icons';
import Info from '@components/common/info';
import useResponsive from '@hooks/helpers/useResponsive';

interface IProps {
    card?: TCard;
    refetchCard: () => void;
    isCardLoading?: boolean;
}

const CardDataSection: FC<IProps> = ({ card, refetchCard, isCardLoading }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'xeppt_card.card_data'
    });
    const { submitLocale } = useLocales();
    const navigate = useNavigate();
    const {
        handleOpenAddMoneyModal,
        handleOpenManageModal,
        handleActivateCard,
        handleOpeCardDetails
    } = useXepptCardModals({ refetchCard, card });

    return (
        <div className={styles.wrapper}>
            <Button leftIcon="arrow_left" onClick={() => navigate(-1)}>
                {submitLocale('back')}
            </Button>
            <Typography className={styles.title} variant="h4">
                {capitalizeFirstLetter(prettifyCardType(card?.type))} {t('card')}{' '}
                <span>{hideCardNumber(card?.cardNumber)}</span>
            </Typography>
            <div className={styles.content}>
                <div className={styles.left_content}>
                    {isCardLoading ? (
                        <Skeleton className={styles.card_skeleton} />
                    ) : (
                        card && <Card isBalance={true} card={card} />
                    )}
                    <div className={styles.actions_wrapper}>
                        {card?.status === ECardStatus.ACTIVE && (
                            <div className={styles.action}>
                                <Button
                                    variant="icon-dark"
                                    leftIcon="plus"
                                    onClick={handleOpenAddMoneyModal}
                                />
                                <p>{t('add_money')}</p>
                            </div>
                        )}
                        {(card?.status === ECardStatus.WAITING_FOR_ACTIVATION ||
                            card?.status === ECardStatus.TEMPORARY_CLOSED) && (
                            <div className={styles.action}>
                                <Button
                                    variant="icon"
                                    leftIcon="power"
                                    onClick={handleActivateCard}
                                />
                                <p>{t('activate')}</p>
                            </div>
                        )}
                        <div className={styles.action}>
                            <Button
                                variant="icon"
                                leftIcon="credit_card"
                                onClick={handleOpeCardDetails}
                            />
                            <p>{t('card_details')}</p>
                        </div>
                        {/*{card.status === ECardStatus.ACTIVE && (*/}
                        {/*    <div className={styles.action}>*/}
                        {/*        <Button variant="icon" leftIcon="lock" onClick={handleOpenLockCard} />*/}
                        {/*        <p>{t('lock_card')}</p>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{card.status === ECardStatus.CLOSED && (*/}
                        {/*    <div className={styles.action}>*/}
                        {/*        <Button variant="icon" leftIcon="lock" onClick={handleOpenUnlockCard} />*/}
                        {/*        <p>{t('unlock_card')}</p>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {card?.status === ECardStatus.ACTIVE && (
                            <div className={styles.action}>
                                <Button
                                    variant="icon"
                                    leftIcon="settings"
                                    onClick={handleOpenManageModal}
                                />
                                <p>{t('manage_card')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardDataSection;
