import { useEffect, useState } from 'react';
import { ENewNotificationType } from '@enum';
import { useNotificationsContext } from '@hooks/context/useNotificationsContext';

interface IProps<T, R> {
    method: (data?: T) => Promise<R>;
    data?: T;
    isInitialRequest?: boolean;
    deps?: any[];
    onSuccess?: (data: R, requestData?: T) => void;
    onError?: (error: any) => void;
    onFinish?: () => void;
    condition?: boolean;
    notificationType?: ENewNotificationType;
}

export const useApiQuery = <T, R>({
    method,
    deps = [],
    isInitialRequest: isInitial = true,
    data: requestData,
    condition = true,
    onError,
    onSuccess,
    onFinish,
    notificationType
}: IProps<T, R>) => {
    const [isInitialRequest, setIsInitialRequest] = useState(isInitial);
    const [response, setResponse] = useState<R | undefined>(undefined);
    const [error, setError] = useState<string>();
    const [isLoading, setLoading] = useState(false);
    const { newNotificationType } = useNotificationsContext();

    const handleRequest = async (requestData?: T) => {
        setLoading(true);
        setError(undefined);
        method(requestData)
            .then((result) => {
                setResponse(result);
                if (onSuccess) onSuccess(result, requestData);
            })
            .catch((error) => {
                setError(error?.errorCode || '');
                if (onError) onError(error);
            })
            .finally(() => {
                setLoading(false);
                if (onFinish) onFinish();
            });
    };

    useEffect(() => {
        if (isInitialRequest) {
            if (condition) {
                handleRequest(requestData);
            }
        }

        setIsInitialRequest(true);
    }, deps);

    useEffect(() => {
        if (notificationType === newNotificationType) {
            if (condition) {
                handleRequest(requestData);
            }
        }
    }, [newNotificationType, notificationType]);

    return {
        data: response,
        error,
        isLoading,
        handleRequest,
        removeResponse: () => setResponse(undefined)
    };
};
