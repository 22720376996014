import React, { useState } from 'react';
import { pageAnimation } from '@const/animation';
import styles from './styles.module.scss';
import { motion } from 'framer-motion';
import Typography from '@components/common/typography';
import { useUserContext } from '@hooks/context/useUserContext';
import { Icon } from '@components/icons';
import Info from '@components/common/info';
import Button from '@components/common/button';
import Input from '@components/common/input';
import RangeDatePicker from '@components/range_date_picker';
import Table from '@components/table';
import moment from 'moment-timezone';
import { useCashbackModals } from '@hooks/modals/useCashbackModals';
import { getDisableFeature, getTransactionStatus } from '@utils/index';
import Tabs from '@components/tabs';
import { useTranslation } from 'react-i18next';
import { ETransactionStatus, TTransaction } from '@xeppt/xeppt-sdk/types';
import Status from '@components/common/status';
import { ETransactionStatusTranslate } from '@locales/index';
import { useLocales } from '@hooks/helpers/useLocales';
import { useDebounceValue } from 'usehooks-ts';
import CashbackTable from '@sections/cashback/cashback_table';
import WithdrawnTable from '@sections/cashback/withdrawn_table';

export enum ECashbackTab {
    CASHBACK = 'cashback',
    WITHDRAWN = 'withdrawn'
}

const tabs = [ECashbackTab.CASHBACK, ECashbackTab.WITHDRAWN];

const CashbackLayout = () => {
    const { t } = useTranslation('cashback');
    const { user, cashback } = useUserContext();
    const { handleOpenCashbackModal } = useCashbackModals();
    const [activeTab, setActiveTab] = useState<ECashbackTab>(ECashbackTab.CASHBACK);
    const [search, setSearch] = useState('');
    const [debouncedSearch] = useDebounceValue(search, 500);
    const { submitLocale, labelLocale } = useLocales();
    const [dates, setDates] = useState<{ dateFrom?: Date; dateTo?: Date }>({
        dateFrom: undefined,
        dateTo: undefined
    });

    const handleChangeTab = (tab: string) => {
        setActiveTab(tab as ECashbackTab);
        setSearch('');
        setDates({
            dateFrom: undefined,
            dateTo: undefined
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4" style={{ marginBottom: 8 }}>
                {t('hello')}, {user?.profile.firstName}!
            </Typography>
            <Typography variant="body3">{t('description')}</Typography>
            <div className={styles.points_wrapper}>
                <div className={`${styles.point_wrapper} ${styles.green}`}>
                    <div className={styles.icon}>
                        <Icon name="check_outlined" />
                    </div>
                    <div className={styles.text_wrapper}>
                        <div className={styles.title}>
                            <Typography variant="body3">{t('available')}</Typography>
                            <Info variant="bottom" content={<>{t('available_info')}</>} />
                        </div>
                        <Typography variant="h3">${cashback?.available?.toFixed(2)}</Typography>
                        {(cashback?.available || 0) < 5 && (
                            <Typography variant="body3" className={styles.error}>
                                {t('error_1')} <b>${(5 - (cashback?.available || 0)).toFixed(2)}</b>{' '}
                                {t('error_2')}
                            </Typography>
                        )}
                    </div>
                    {(cashback?.available || 0) >= 5 && (
                        <Button
                            variant="primary"
                            className={styles.button}
                            leftIcon="arrow_down"
                            onClick={handleOpenCashbackModal}
                            size="normal">
                            {submitLocale('redeem_now')}
                        </Button>
                    )}
                </div>
                <div className={`${styles.point_wrapper} ${styles.pending}`}>
                    <div className={styles.icon}>
                        <Icon name="watches" />
                    </div>
                    <div className={styles.text_wrapper}>
                        <div className={styles.title}>
                            <Typography variant="body3">{t('pending')}</Typography>
                            <Info variant="bottom" content={<>{t('pending_info')}</>} />
                        </div>
                        <Typography variant="h3">${cashback?.pending?.toFixed(2)}</Typography>
                    </div>
                </div>
                <div className={`${styles.point_wrapper} ${styles.total}`}>
                    <div className={styles.icon}>
                        <Icon name="cash_bank" />
                    </div>
                    <div className={styles.text_wrapper}>
                        <div className={styles.title}>
                            <Typography variant="body3">{t('withdrawn')}</Typography>
                            <Info variant="bottom" content={<>{t('withdrawn_info')}</>} />
                        </div>
                        <Typography variant="h3">${cashback?.total?.toFixed(2)}</Typography>
                    </div>
                </div>
            </div>
            <Tabs
                onChange={handleChangeTab}
                tabs={tabs.map((item) => ({ label: t(`tabs.${item}`), value: item }))}
                activeTab={activeTab}
                className={styles.tabs}
            />
            <div className={styles.table_header}>
                {/*<Typography variant="h5">*/}
                {/*    {t(*/}
                {/*        activeTab === ECashbackTab.CASHBACK*/}
                {/*            ? 'cashback_history'*/}
                {/*            : 'withdraw_history'*/}
                {/*    )}*/}
                {/*</Typography>*/}
                <div className={styles.filter_row}>
                    {activeTab === ECashbackTab.CASHBACK ? (
                        <Input
                            inputClassName={styles.search_input}
                            className={styles.search}
                            leftIcon="search"
                            value={search}
                            onChange={(val) => setSearch(val)}
                            placeholder={t(
                                activeTab === ECashbackTab.CASHBACK
                                    ? 'search_cashback'
                                    : 'search_withdrawn'
                            )}
                        />
                    ) : (
                        <div />
                    )}
                    <RangeDatePicker
                        inputClassName={styles.range_input}
                        className={styles.range}
                        placeholder={labelLocale('date_range')}
                        values={[
                            dates.dateFrom?.toString() || null,
                            dates.dateTo?.toString() || null
                        ]}
                        maxDate={getDisableFeature()}
                        onChange={(val) => {
                            setDates(() => ({
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            }));
                        }}
                    />
                </div>
            </div>
            {activeTab === ECashbackTab.CASHBACK && (
                <CashbackTable
                    search={debouncedSearch}
                    dateTo={dates.dateTo}
                    dateFrom={dates.dateFrom}
                />
            )}
            {activeTab === ECashbackTab.WITHDRAWN && (
                <WithdrawnTable dateTo={dates.dateTo} dateFrom={dates.dateFrom} />
            )}
        </motion.div>
    );
};

export default CashbackLayout;
